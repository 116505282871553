import React, {useRef} from 'react';
import {
    enumConverter,
    getDataFromNestedObject,
    getFracDigit,
    moneyFormatter,
    tableDateTimeFormatter
} from "../../../../helpers/Utils";
import {IMAGE_URL} from "../../../../helpers/Constants";
import numberToWords from "number-to-words";

const SaleInvoiceA4 = ({componentRef, data, pdfConfig}) => {

    const saleInvoiceTable = useRef();

    const columns = [
        {
            title: <b>SL.</b>,
            key: 'slNo',
            render: (e, index) => (
                <div className='print-text'>
                    {index + 1}
                </div>
            )
        },
        {
            title: <b>Name</b>,
            key: 'name',
            render: e => e?.productName
        },
        {
            title: <b>Price(Pcs)</b>,
            key: 'unitPrice',
            render: e => (
                <div className='print-price'>
                    {e.salePrice?.toFixed(getFracDigit())}
                </div>
            )
        },
        {
            title: <b>Qty</b>,
            key: 'quantity',
            render: e => (
                <div className='print-text'>
                    {e.quantity?.toFixed(getFracDigit())}
                </div>
            )
        },
        {
            title: <b>Total</b>,
            key: 'totalSalePrice',
            render: e => (
                <div className='print-text'>
                    {e.totalSalePrice?.toFixed(getFracDigit())}
                </div>
            )
        }
    ];

    const getPageMargins = () => {
        return `@page {
                    margin: 0px !important;
                 }
                 @media print {
                     .page-break {
                        page-break-before: always;
                    }
                }`;
    };

    return (
        <div
            ref={componentRef}
            className="sale-invoice"
        >
            <style>{getPageMargins()}</style>

            {
                pdfConfig?.showLogo &&
                <div style={{display: 'flex', justifyContent: 'center', marginBottom: '10px', marginTop: '10px'}}>
                    <img
                        src={`${IMAGE_URL}${pdfConfig?.logo}?dummy=${new Date().getMilliseconds()}`}
                        alt="avatar"
                        className='sale-print-logo'
                    />
                </div>
            }

            {
                pdfConfig?.topTextBold?.split("\n")?.map((v, index) =>
                    <p key={index} className="header-p-bold" style={{}}>
                        {v}
                    </p>
                )
            }

            {
                pdfConfig?.topText?.split("\n")?.map((v, index) =>
                    <p key={index} className="header-p">
                        {v}
                    </p>
                )
            }

            {/*<h4 className="receipt-text">*/}
            {/*    Sales Receipt*/}
            {/*</h4>*/}

            <div className="sale-info-sale-print">

                {
                    data?.servedByRow ?
                        <div className='main'>
                            <b className='heading'>
                                Served By -
                            </b>
                            <span className='heading'>
                                {
                                    data?.userFullName && data?.userFullName
                                }
                            </span>
                        </div>
                        : null
                }

                <div className='main'>
                    <b className='heading'>
                        Sales No -
                    </b>
                    <span className='heading'>
                        {
                            data?.saleNumber?.substring(data?.saleNumber?.length - 11)
                        }
                    </span>
                </div>

                {
                    data?.chalanNumber &&
                    <div className='main'>
                        <b className='heading'>
                            Chalan No -
                        </b>
                        <span className='heading'>
                            {
                                data?.chalanNumber
                            }
                        </span>
                    </div>
                }
                <div className='main'
                     style={{
                         borderBottom: '1px solid #DDDDDD',
                         paddingBottom: '5px'
                     }}
                >
                    <b className='heading'>
                        Date & Time -
                    </b>
                    <span className='heading'>
                        {
                            tableDateTimeFormatter(data.createdAt)
                        }
                    </span>
                </div>

                {
                    data?.configCustomerName ?

                        <div className='main'>
                            <b className='heading'>
                                Bill To -
                            </b>
                            <span className='heading'>
                                {
                                    data?.customerName && data?.customerName
                                }
                            </span>
                        </div>
                        : null
                }

                {
                    data?.configCustomerPhoneNumber ?

                        <div className='main'>
                            <b className='heading'>
                                Phone -
                            </b>
                            <span className='heading'>
                                {
                                    data?.customerPhoneNumber && data?.customerPhoneNumber
                                }
                            </span>
                        </div>
                        : null
                }

                {
                    data?.configCustomerAddress ?
                        <div className='main'>
                            <b className='heading'>
                                Address -
                            </b>
                            <span className='heading'>
                                {
                                    data?.customerAddress && data?.customerAddress
                                }
                            </span>
                        </div>
                        : null
                }

            </div>

            <div ref={saleInvoiceTable}>
                <table>
                    <thead>
                    <tr>
                        {
                            columns?.map(column => <td
                                    style={{
                                        textAlign: "center",
                                        fontWeight: 'bold',
                                    }}
                                    key={column.key}
                                >
                                    {column.title}
                                </td>
                            )
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data && data.saleDetailsList?.map((saleDetails, index) => (
                            <tr key={index}>
                                {
                                    columns?.map(column => {
                                        return (
                                            <td key={column.key}>
                                                {
                                                    column.render ? column.render(saleDetails, index) :
                                                        getDataFromNestedObject(saleDetails, column.dataIndex)
                                                }
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        ))
                    }
                    <tr>
                        <td colSpan={columns?.length} style={{border: "1px solid #fff"}}>
                            <div className="table-footer">
                                <div className="table-footer-content">
                                    <p>
                                        <b>Total</b>
                                        <span className="bordered-span" style={{
                                            fontWeight: 'bold'
                                        }}>{moneyFormatter(data.totalPrice?.toFixed(getFracDigit()))}</span>
                                    </p>
                                    {
                                        !pdfConfig?.simplePrint &&
                                        <div>
                                            {
                                                pdfConfig?.cardFeeRow ? <p>
                                                    <b>Vat</b>
                                                    <span
                                                        className="normal-span">{moneyFormatter(data.vatAmount?.toFixed(getFracDigit()))}</span>
                                                </p> : null
                                            }

                                            {
                                                pdfConfig?.discountRow ? <div>
                                                    {
                                                        data.discountType ?
                                                            <p>
                                                                {
                                                                    (!data.discountType || data.discountType === "FLAT") &&
                                                                    <b>Discount</b>
                                                                }
                                                                {
                                                                    (data.discountType === "PERCENTAGE") &&
                                                                    <b>Discount ({data.discountPercent}%)</b>
                                                                }
                                                                <span className="normal-span">-&nbsp;
                                                                    {moneyFormatter(data.discount?.toFixed(getFracDigit()))}
                                                        </span>
                                                            </p> :
                                                            <p>
                                                                <b>Discount</b>
                                                                <span
                                                                    className="normal-span">{moneyFormatter(data.discountAmount)}</span>
                                                            </p>
                                                    }
                                                </div> : null
                                            }

                                            <p style={{marginBottom: '10px'}}>
                                                <b>Net Amount(TK)</b>
                                                <span className="bordered-span" style={{
                                                    fontWeight: 'bold'
                                                }}>{moneyFormatter(data.finalPrice?.toFixed(getFracDigit()))}</span>
                                            </p>

                                            {
                                                data.payments?.length > 0 ? data.payments?.map(payment =>
                                                        <p key={payment.paymentType?.name}>
                                                            <b>{enumConverter(payment.paymentType?.name)}</b>
                                                            <span
                                                                className="normal-span">{moneyFormatter(payment.amount?.toFixed(getFracDigit()))}</span>
                                                        </p>
                                                    ) :
                                                    <>
                                                        <p>
                                                            <b>{data.paymentType?.name}</b>
                                                            <span className="normal-span">
                                                        {moneyFormatter(data.paidAmount?.toFixed(getFracDigit()))}
                                                    </span>
                                                        </p>
                                                    </>
                                            }
                                            <p style={{marginBottom: '10px', fontWeight: 'bold'}}>
                                                <b>Total Paid(TK)</b>
                                                <span
                                                    className="bordered-span">{moneyFormatter(data.paidAmount?.toFixed(getFracDigit()))}</span>
                                            </p>

                                            {
                                                pdfConfig?.totalPaidInWord &&
                                                <p style={{marginBottom: '10px', fontWeight: 'bold'}}>
                                                    <b>Total Paid In Word</b>
                                                    <span
                                                        className="bordered-span-down">
                                                                {
                                                                    numberToWords.toWords(data.paidAmount).toUpperCase() + ' TAKA ONLY'
                                                                }
                                                            </span>
                                                </p>
                                            }

                                            <p>
                                                <b>Due</b>
                                                <span
                                                    className="normal-span">{moneyFormatter(data.dueAmount?.toFixed(getFracDigit()))}</span>
                                            </p>
                                        </div>
                                    }
                                </div>

                                {/*<div className='additional-info'>*/}
                                {/*    {*/}
                                {/*        pdfConfig?.bottomText ? pdfConfig?.bottomText?.split("\n")?.map((v, index) =>*/}
                                {/*            <p key={index} style={{marginBottom: "5px"}}>*/}
                                {/*                {v}*/}
                                {/*            </p>*/}
                                {/*        ) : null*/}
                                {/*    }*/}
                                {/*</div>*/}

                                {
                                    (pdfConfig?.customerSignature || pdfConfig?.ownerSignature) &&
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '80px 80px 20px 80px',
                                        columnGap: '25px'
                                    }}>
                                        {
                                            pdfConfig?.customerSignature &&
                                            <div style={{borderTop: '1px solid black', paddingBottom: '5px'}}>
                                                {pdfConfig?.customerSignature}
                                            </div>
                                        }

                                        {
                                            pdfConfig?.ownerSignature &&
                                            <div style={{borderTop: '1px solid black', paddingBottom: '5px'}}>
                                                {pdfConfig?.ownerSignature}
                                            </div>
                                        }
                                    </div>
                                }

                                <div className='additional-info' style={{border: 'none'}}>
                                    {
                                        pdfConfig?.secondBottomText ? pdfConfig?.secondBottomText?.split("\n")?.map((v, index) =>
                                            <p key={index} style={{marginBottom: "5px"}}>
                                                {v}
                                            </p>
                                        ) : null
                                    }
                                </div>

                                <div className={`${pdfConfig?.bottomText !== '' ? 'additional-info' : ''}`}>
                                    {
                                        pdfConfig?.bottomText ? pdfConfig?.bottomText?.split("\n")?.map((v, index) =>
                                            <p key={index} style={{marginBottom: "5px"}}>
                                                {v}
                                            </p>
                                        ) : null
                                    }
                                </div>

                                <p className="additional-info" style={{border: 'none'}}>
                                    Get Your Desired Solution | Visit zaagsys.com
                                </p>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SaleInvoiceA4;