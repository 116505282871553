import { useLocation } from "react-router-dom";
import moment from "moment";
import Permissions from "./Permissions";
import {
    CURRENCY_DIGIT_KEY,
    CURRENCY_KEY,
    GREEN,
    IMAGE_URL,
    POS_GENERATE_REQUISITION_PDF,
    POS_GET_SALE_INVOICE,
    RED,
} from "./Constants";
import React from "react";
import * as _ from "lodash";
import DialingCodes from "../json/DialingCodeList.json";

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {
        data = { ...data, [key]: query.get(key) || "" };
    }
    return data;
};

export const getErrorMessage = (error) => {
    if (error.response) {
        if (error.response.data) {
            return error.response.data.message;
        }

        return error.response.data;
    } else {
        return error.message;
    }
};

export const isValueExistInSearch = (data) => {
    for (const key of Object.keys(data)) {
        if (data[key]) {
            return ["1"];
        }
    }
    return ["0"];
};

export const dateFormatForPrint = "DD-MM-YYYY hh:mm:ss a";
export const dateFormatForSend = "DD-MM-YYYY HH:mm:ss";
// export const currentDate = () => moment(Date.now()).date(moment().date() - 2).month(moment().month()).set({
export const currentDate = () =>
    moment(Date.now() - 2 * 24 * 3600 * 1000)
        .set({
            h: 0,
            m: 0,
            s: 0,
        })
        .format(dateFormatForSend);
// export const nextDate = () => moment(Date.now()).month(moment().month()).set({
export const nextDate = () =>
    moment(Date.now())
        .set({
            h: 23,
            m: 59,
            s: 59,
        })
        .format(dateFormatForSend);

export const dateTimeFormatterFrom = (value) => {
    // Parse the input with the correct format
    const parsedDate = moment(value, "DD-MM-YYYY HH:mm:ss");

    // Set the time to 23:59:59 and format the output
    return parsedDate
        .set({
            h: 0,
            m: 0,
            s: 0,
        })
        .format("DD-MM-YYYY HH:mm:ss");
};

export const dateTimeFormatter = (value) => {
    // Parse the input with the correct format
    const parsedDate = moment(value, "DD-MM-YYYY HH:mm:ss");

    // Set the time to 23:59:59 and format the output
    return parsedDate
        .set({
            h: 23,
            m: 59,
            s: 59,
        })
        .format("DD-MM-YYYY HH:mm:ss");
};

export const getMoment = (value) =>
    moment(value, "DD-MM-YYYY HH:mm:ss").format(dateFormatForPrint);

export const getShopId = () => {
    return JSON.parse(localStorage.getItem("selectedShop")).id;
};

export const getShopName = () => {
    return JSON.parse(localStorage.getItem("selectedShop")).shopName;
};

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach((key) => {
        if (!params[key]) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {
        if (index === 0) {
            result += `?${key}=${params[key]}`;
        } else {
            result += `&${key}=${params[key]}`;
        }
    });

    return result;
};

// Fraction digit set
export const getFracDigit = () => {
    if (localStorage.getItem(CURRENCY_DIGIT_KEY)) {
        return convertStringToNumber(localStorage.getItem(CURRENCY_DIGIT_KEY));
    }
    return 2;
};

export const convertStringToNumber = (value) => {
    const convertValue = {
        THREE: 3,
        TWO: 2,
    };

    if (value) {
        return convertValue[value];
    }
    return 2;
};

export const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: getFracDigit(),
    maximumFractionDigits: getFracDigit(),
});

export const moneyFormatter = (value) => {
    const currency = getCurrency();

    if (!value) {
        return `${currency} ${formatter.format(0)}`;
    }

    return `${currency} ${formatter.format(value)}`;
};

export const getCurrency = () => {
    return localStorage.getItem(CURRENCY_KEY);
};

export const getFullPreviousRoute = (history) => {
    return `${history.location.pathname}${history.location.search}`;
};

export const resetState = (data) => {
    for (const key of Object.keys(data)) {
        data = { ...data, [key]: "" };
    }
    return data;
};

export const getAllNonObjectValues = (data) => {
    let singleValue = {};

    for (const [key, value] of Object.entries(data)) {
        if (typeof value !== "object") {
            singleValue[key] = value;
        }
    }
    return singleValue;
};

export const decryption = (encryptValue) => {
    let result = "";

    for (let i = 0; i < encryptValue.length; i++) {
        let decryptChar1 = String.fromCharCode(encryptValue.charCodeAt(i) ^ 6);
        let decryptChar2 =
            decryptChar1.charCodeAt(0) % 2 === 0
                ? String.fromCharCode(decryptChar1.charCodeAt(0) + 1)
                : String.fromCharCode(decryptChar1.charCodeAt(0) - 1);
        result += decryptChar2;
    }

    return result;
};

export const generateLocalId = (shopId, userId) => {
    return `${shopId}_${userId}_${new Date().getTime()}`;
};

export const monthTextToNumber = (month) => {
    const monthName = month;

    // Create a Date object with the desired month name
    const date = new Date(`${monthName} 1, 2000`);

    // Get the numerical value of the month (0-indexed)
    const monthNumber = date.getMonth() + 1;

    const formattedMonth =
        monthNumber > 9 ? `${monthNumber}` : `0${monthNumber}`;

    return formattedMonth;
};

export const timestampFormatter = (date) => {
    // Convert the timestamp to a moment object
    const momentObj = moment(date);

    // Format the moment object into the desired format
    const formattedDate = momentObj.format("DD-MMM-YYYY, h:mm A");

    return formattedDate;
};

export const hasPermission = (loggedInUserPermissions, permissions) => {
    if (!permissions) {
        return false;
    }

    if (permissions.includes(Permissions.ALL)) {
        return true;
    }

    if (!loggedInUserPermissions) {
        return false;
    }

    for (const permission of permissions) {
        if (loggedInUserPermissions.includes(permission)) {
            return true;
        }
    }

    return false;
};
// export const hasPermission = (loggedInUserPermissions, permissions) => {
//
//     if (permissions.includes(Permissions.ALL)) {
//         return true;
//     }
//
//     for (const permission of permissions) {
//
//         if (loggedInUserPermissions.includes(permission)) {
//             return true;
//         }
//
//     }
//
//     return false;
// }

export const enumConverter = (value) => {
    if (!value) return;

    const words = value.toLowerCase().split("_");
    return words
        .map((value) => value[0].toUpperCase() + value.substring(1))
        .join(" ");
};

export const tableDateTimeFormatter = (date) => {
    if (!date) return;
    return moment
        .utc(date, "DD-MM-YYYY HH:mm:ss")
        .local()
        .format("D MMM YYYY hh:mm a");
};

export const tableDateFormatter = (date) => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("D MMM YYYY");
};

export const generateLocalStorageKey = (name) => {
    const shop = JSON.parse(localStorage.getItem("selectedShop"));
    return `${name}_${shop && shop.shopName}`;
};

export const calculateDiscount = (data) => {
    if (data.discountType === "FLAT") {
        return data.discountAmount;
    }

    return parseFloat(
        ((data.totalPrice * data.discountPercent) / 100).toFixed(getFracDigit())
    );
};

export const unitTypeByParent = (parentType) => {
    if (parentType === "PIECE") {
        return [{ value: "PIECE", name: "PIECE" }];
    }
    if (["KG", "GRAM"].includes(parentType)) {
        return [
            { value: "KG", name: "KG" },
            { value: "GRAM", name: "GRAM" },
        ];
    }
    if (["LITER", "ML"].includes(parentType)) {
        return [
            { value: "LITER", name: "LITER" },
            { value: "ML", name: "ML" },
        ];
    }
    if (["YARD", "METER", "FEET"].includes(parentType)) {
        return [
            { value: "YARD", name: "YARD" },
            { value: "FEET", name: "FEET" },
            { value: "METER", name: "METER" },
        ];
    }
};

export const quantityByUnitType = (unitType, value) => {
    switch (unitType) {
        case "PIECE":
        case "KG":
        case "LITER":
        case "YARD":
            return value;
        case "GRAM":
            return value / 1000;
        case "ML":
            return value / 1000;
        case "FEET":
            return value / 3;
        case "METER":
            return value / 1.09361;
        default:
            return value;
    }
};

export const reverseQuantityByUnitType = (unitType, value) => {
    switch (unitType) {
        case "PIECE":
        case "KG":
        case "LITER":
        case "YARD":
            return value;
        case "GRAM":
            return value * 1000;
        case "ML":
            return value * 1000;
        case "FEET":
            return value * 3;
        case "METER":
            return value * 1.09361;
        default:
            return value;
    }
};

export const requisitionPDFDownloadURL = (shopId, requisitionId) => {
    return `${POS_GENERATE_REQUISITION_PDF}/${shopId}/${requisitionId}?x-token=${localStorage.getItem(
        "ems_access_token"
    )}`;
};

export const saleInvoicePDFDownloadURL = (shopId, saleLocalId) => {
    return `${POS_GET_SALE_INVOICE}/${shopId}/${saleLocalId}?x-token=${localStorage.getItem(
        "ems_access_token"
    )}`;
};

export const currentDateStamp = (month, year) => {
    const targetDate = new Date(month + year);
    const currentTimestamp = targetDate.getTime();
    return currentTimestamp;
};

export const oneMonthAhedDateStamp = () => {
    const oneMonthAheadDate = new Date();
    oneMonthAheadDate.setMonth(oneMonthAheadDate.getMonth());
    const oneMonthAheadTimestamp = oneMonthAheadDate.getTime();
    return oneMonthAheadTimestamp;
};

export const getDataFromNestedObject = (object, dataIndex) => {
    if (!dataIndex) return null;

    let split = dataIndex.split(".");
    let value = object;

    for (let i = 0; i < split.length; i++) {
        value = value[split[i]];
    }

    return value;
};

export const getProductName = (product) => {
    if (!product || !product.productName) return "Product name missing";

    return (
        <div style={{ margin: "0px", fontWeight: "600", fontSize: "15px" }}>
            {product.productName.split("\n").map((v, index) => (
                <p key={index} style={{ margin: "0" }}>
                    {v}
                </p>
            ))}
        </div>
    );
};

export const getInvoiceProductName = (product, fontSize) => {
    if (!product || !product.productName) return "Product name missing";

    return (
        <>
            <div
                style={{
                    margin: "0px",
                    fontWeight: "600",
                    fontSize: fontSize || "15px",
                }}
            >
                {product.productName.split("\n").map((v, index) => (
                    <p key={index} style={{ margin: "0" }}>
                        {v}
                    </p>
                ))}
            </div>

            <p style={{ margin: "0", fontSize: fontSize || "14px" }}>
                {product.companyName}
            </p>
            <p style={{ margin: "0", fontSize: fontSize || "14px" }}>
                {product.productType}
            </p>
        </>
    );
};

export const getImagePath = (value) => {
    if (value) {
        return `${IMAGE_URL}${value}?dummy=${new Date().getMilliseconds()}`;
    }
    return "";
};

export const paymentStatus = (amount, className) => {
    if (amount === 0) {
        return (
            <span
                className={className ? className : ""}
                style={{ color: "#0ACF83" }}
            >
                Paid
            </span>
        );
    } else {
        return (
            <span
                className={className ? className : ""}
                style={{ color: "#D9333F" }}
            >
                Unpaid
            </span>
        );
    }
};

// export const paymentStatus = (amount, className) => {
//     if (amount === 0) {
//         return <p className={className ? className : ""} style={{color: "#0ACF83"}}>Paid</p>
//     } else {
//         return <p className={className ? className : ""} style={{color: "#D9333F"}}>Unpaid</p>
//     }
// }

export const getRemainingPayment = (amount) => {
    if (Math.abs(amount) === 0) {
        return {
            title: "",
            amount: 0,
            color: "#000000",
            status: "",
        };
    }

    if (amount < 0) {
        return {
            title: "amount",
            amount: amount * -1,
            color: RED,
            status: "due",
        };
    } else {
        return {
            title: "Advance",
            amount: amount,
            color: GREEN,
            status: "advance",
        };
    }
};

export const getConfigText = (value, className) => {
    if (value) {
        return value.split("\n")?.map((v, index) => {
            return (
                <div
                    key={`${index}-${Math.random()}`}
                    className={className ? className : ""}
                    style={{ margin: "0" }}
                >
                    {v}
                </div>
            );
        });
    }
    return null;
};

export const getRoundValue = (value) => {
    if (value) {
        return _.round(value, getFracDigit());
    }

    return 0;
};

export const getPaymentType = (value) => {
    if (value) {
        if (value.toLowerCase().includes("bkash")) {
            return "MFS";
        } else {
            return value;
        }
    }

    return "";
};

export const getCountryPhoneNumber = (mobileNumber, selectedDialCode) => {
    let contact;

    if (mobileNumber) {
        contact = `${JSON.parse(selectedDialCode).phone}${mobileNumber}`;
    }

    return contact;
};

export const getCountryCode = (selectedDialCode) => {
    let code;

    code = JSON.parse(selectedDialCode).code;

    return code;
};

export const getPhoneLengthByCountryCode = (countryCode) => {
    if (countryCode) {
        const find = DialingCodes.find(
            (dialingCode) =>
                dialingCode.code.toUpperCase() === countryCode.toUpperCase()
        );

        if (find) {
            return find.phoneLength;
        }
    }

    return 0;
};

export const getDialingNumberByCountryCode = (countryCode) => {
    return DialingCodes.find(
        (dialingCode) =>
            dialingCode.code.toUpperCase() === countryCode.toUpperCase()
    );
};
