const Permissions = {
    ALL: "ALL",

    // -------- auth ------------
    READ_USER: "READ_USER",
    MODIFY_USER: "MODIFY_USER",
    READ_CUSTOMER: "READ_CUSTOMER",
    READ_CUSTOMER_GROUP: "READ_CUSTOMER_GROUP",
    CREATE_USER: "CREATE_USER",
    READ_PROFILE: "READ_PROFILE",

    READ_SHOP_OWNER: "READ_SHOP_OWNER",

    READ_SALES_PERSON: "READ_SALES_PERSON",
    DELETE_SALES_PERSON: "READ_SALES_PERSON",
    CREATE_SALES_PERSON: "CREATE_SALES_PERSON",
    MODIFY_SALES_PERSON: "MODIFY_SALES_PERSON",

    READ_ROLE: "READ_ROLE",
    CREATE_ROLE: "CREATE_ROLE",
    MODIFY_ROLE: "MODIFY_ROLE",

    CREATE_SHOP_ROLE: "CREATE_SHOP_ROLE",
    READ_SHOP_ROLE: "READ_SHOP_ROLE",
    MODIFY_SHOP_ROLE: "MODIFY_SHOP_ROLE",

    READ_SHOP: "READ_SHOP",
    CREATE_SHOP: "CREATE_SHOP",
    MODIFY_SHOP: "MODIFY_SHOP",

    READ_GLOBAL_CONFIG: "READ_GLOBAL_CONFIG",
    CREATE_GLOBAL_CONFIG: "CREATE_GLOBAL_CONFIG",
    MODIFY_GLOBAL_CONFIG: "MODIFY_GLOBAL_CONFIG",
    DELETE_GLOBAL_CONFIG: "DELETE_GLOBAL_CONFIG",

    READ_BANNER: "READ_BANNER",
    CREATE_BANNER: "CREATE_BANNER",
    MODIFY_BANNER: "MODIFY_BANNER",

    // --------------------------  pos ------------------------------

    // pos-Customers===================
    READ_POS_CUSTOMER: "READ_POS_CUSTOMER",
    CREATE_POS_CUSTOMER: "CREATE_POS_CUSTOMER",
    MODIFY_POS_CUSTOMER: "MODIFY_POS_CUSTOMER",
    DELETE_POS_CUSTOMER: "DELETE_POS_CUSTOMER",
    SYNC_POS_CUSTOMER: "SYNC_POS_CUSTOMER",

    // pos-Expense======================
    READ_POS_EXPENSE: "READ_POS_EXPENSE",
    CREATE_POS_EXPENSE: "CREATE_POS_EXPENSE",
    MODIFY_POS_EXPENSE: "MODIFY_POS_EXPENSE",
    DELETE_POS_EXPENSE: "DELETE_POS_EXPENSE",
    SYNC_POS_EXPENSE: "SYNC_POS_EXPENSE",

    //pos-Inventory=====================
    READ_POS_INVENTORY: "READ_POS_INVENTORY",
    CREATE_POS_INVENTORY: "CREATE_POS_INVENTORY",
    MODIFY_POS_INVENTORY: "MODIFY_POS_INVENTORY",
    DELETE_POS_INVENTORY: "DELETE_POS_INVENTORY",
    SYNC_POS_INVENTORY: "SYNC_POS_INVENTORY",
    READ_POS_PAYMENT: "READ_POS_PAYMENT",
    CREATE_POS_PAYMENT: "CREATE_POS_PAYMENT",
    MODIFY_POS_PAYMENT: "MODIFY_POS_PAYMENT",
    DELETE_POS_PAYMENT: "DELETE_POS_PAYMENT",
    SYNC_POS_PAYMENT: "SYNC_POS_PAYMENT",
    READ_POS_PRODUCT: "READ_POS_PRODUCT",
    CREATE_POS_PRODUCT: "CREATE_POS_PRODUCT",
    MODIFY_POS_PRODUCT: "MODIFY_POS_PRODUCT",
    DELETE_POS_PRODUCT: "DELETE_POS_PRODUCT",
    SYNC_POS_PRODUCT: "SYNC_POS_PRODUCT",
    READ_POS_SALE: "READ_POS_SALE",
    CREATE_POS_SALE: "CREATE_POS_SALE",
    MODIFY_POS_SALE: "MODIFY_POS_SALE",
    DELETE_POS_SALE: "DELETE_POS_SALE",
    SYNC_POS_SALE: "SYNC_POS_SALE",
    READ_POS_VENDOR: "READ_POS_VENDOR",
    CREATE_POS_VENDOR: "CREATE_POS_VENDOR",
    MODIFY_POS_VENDOR: "MODIFY_POS_VENDOR",
    DELETE_POS_VENDOR: "DELETE_POS_VENDOR",
    SYNC_POS_VENDOR: "SYNC_POS_VENDOR",
    READ_POS_REPORT: "READ_POS_REPORT",
    READ_POS_SALE_SUMMERY: "READ_POS_SALE_SUMMERY",
    READ_POS_CUSTOM_REPORT: "READ_POS_CUSTOM_REPORT",
    GENERATE_POS_REPORT: "GENERATE_POS_REPORT",
    READ_POS_INVENTORY_SUMMERY: "READ_POS_INVENTORY_SUMMERY",
    READ_POS_PROFIT: "READ_POS_PROFIT_SUMMERY",
    READ_POS_REQUISITION: "READ_POS_REQUISITION",
    CREATE_POS_REQUISITION: "CREATE_POS_REQUISITION",
    MODIFY_POS_REQUISITION: "MODIFY_POS_REQUISITION",
    DELETE_POS_REQUISITION: "DELETE_POS_REQUISITION",
    SYNC_POS_REQUISITION: "SYNC_POS_REQUISITION",

    // pos-SalesPerson========================
    CREATE_SALES_PERSON_BY_SHOP: "CREATE_SALES_PERSON_BY_SHOP",
    READ_SALES_PERSON_OF_SHOP: "READ_SALES_PERSON_OF_SHOP",
    MODIFY_SALES_PERSON_BY_SHOP: "MODIFY_SALES_PERSON_BY_SHOP",
    DELETE_SALES_PERSON_BY_SHOP: "DELETE_SALES_PERSON_BY_SHOP",
    CASH_RECEIVED_POS_SALE: "CASH_RECEIVED_POS_SALE",
    SHOP_CHECK_AS_ONLINE: "SHOP_CHECK_AS_ONLINE",
    READ_POS_SHOP_USER: "READ_POS_SHOP_USER",
    CREATE_POS_SHOP_USER: "CREATE_POS_SHOP_USER",
    MODIFY_POS_SHOP_USER: "MODIFY_POS_SHOP_USER",
    DELETE_POS_SHOP_USER: "DELETE_POS_SHOP_USER",
    SYNC_POS_SHOP_USER: "SYNC_POS_SHOP_USER",

    // pos-Transactions=====================
    READ_POS_SHOP_USER_TRANSACTION: "READ_POS_SHOP_USER_TRANSACTION",
    CREATE_POS_SHOP_USER_TRANSACTION: "CREATE_POS_SHOP_USER_TRANSACTION",
    MODIFY_POS_SHOP_USER_TRANSACTION: "MODIFY_POS_SHOP_USER_TRANSACTION",
    DELETE_POS_SHOP_USER_TRANSACTION: "DELETE_POS_SHOP_USER_TRANSACTION",
    SYNC_POS_SHOP_USER_TRANSACTION: "SYNC_POS_SHOP_USER_TRANSACTION",

    // pos reconciliation
    CREATE_RECONCILIATION: "CREATE_RECONCILIATION",
    CANCEL_RECONCILIATION: "CANCEL_RECONCILIATION",
    READ_RECONCILIATION: "READ_RECONCILIATION",

    //    pos offline config
    READ_OFFLINE_CONFIG: "READ_OFFLINE_CONFIG",
    CREATE_OFFLINE_CONFIG: "CREATE_OFFLINE_CONFIG",
    MODIFY_OFFLINE_CONFIG: "MODIFY_OFFLINE_CONFIG",
    DELETE_OFFLINE_CONFIG: "DELETE_OFFLINE_CONFIG",

    // Admin Product Type
    READ_PRODUCT_TYPE_ADMIN: "READ_POS_PRODUCT_TYPE_FOR_ADMIN",
    CREATE_PRODUCT_TYPE_ADMIN: "CREATE_POS_PRODUCT_TYPE_FOR_ADMIN",
    MODIFY_PRODUCT_TYPE_ADMIN: "MODIFY_POS_PRODUCT_TYPE_FOR_ADMIN",
    DELETE_PRODUCT_TYPE_ADMIN: "DELETE_POS_PRODUCT_TYPE_FOR_ADMIN",

    // Product Type
    READ_PRODUCT_TYPE: "READ_POS_PRODUCT_TYPE",
    CREATE_PRODUCT_TYPE: "CREATE_POS_PRODUCT_TYPE",
    MODIFY_PRODUCT_TYPE: "MODIFY_POS_PRODUCT_TYPE",
    DELETE_PRODUCT_TYPE: "DELETE_POS_PRODUCT_TYPE",

    // Product Company
    READ_POS_SHOP_COMPANY: "READ_POS_SHOP_COMPANY",
    CREATE_POS_SHOP_COMPANY: "CREATE_POS_SHOP_COMPANY",
    MODIFY_POS_SHOP_COMPANY: "MODIFY_POS_SHOP_COMPANY",
    DELETE_POS_SHOP_COMPANY: "DELETE_POS_SHOP_COMPANY",

    // company admin
    READ_POS_SHOP_COMPANY_FOR_ADMIN: "READ_POS_SHOP_COMPANY_FOR_ADMIN",
    CREATE_POS_SHOP_COMPANY_FOR_ADMIN: "CREATE_POS_SHOP_COMPANY_FOR_ADMIN",
    MODIFY_POS_SHOP_COMPANY_FOR_ADMIN: "MODIFY_POS_SHOP_COMPANY_FOR_ADMIN",
    DELETE_POS_SHOP_COMPANY_FOR_ADMIN: "DELETE_POS_SHOP_COMPANY_FOR_ADMIN",

    // Order
    READ_ONLINE_ORDER_CONFIG: "READ_ONLINE_ORDER_CONFIG",
    CREATE_ONLINE_ORDER_CONFIG: "CREATE_ONLINE_ORDER_CONFIG",
    MODIFY_ONLINE_ORDER_CONFIG: "MODIFY_ONLINE_ORDER_CONFIG",
    DELETE_ONLINE_ORDER_CONFIG: "DELETE_ONLINE_ORDER_CONFIG",
    MODIFY_ONLINE_ORDER_CONFIG_BY_ADMIN: "MODIFY_ONLINE_ORDER_CONFIG_BY_ADMIN",
    READ_ONLINE_ORDER: "READ_ONLINE_ORDER",
    CREATE_ONLINE_ORDER: "CREATE_ONLINE_ORDER",
    MODIFY_ONLINE_ORDER: "MODIFY_ONLINE_ORDER",
    DELETE_ONLINE_ORDER: "DELETE_ONLINE_ORDER",
    READ_ONLINE_ORDER_STATES: "READ_ONLINE_ORDER_STATES",
    MODIFY_ONLINE_ORDER_STATUS: "MODIFY_ONLINE_ORDER_STATUS",
    READ_PERSONAL_ONLINE_ORDER: "READ_PERSONAL_ONLINE_ORDER",

    READ_POS_DASHBOARD: "READ_POS_DASHBOARD",
    INVENTORY_MISSING_REPORT: "INVENTORY_MISSING_REPORT",
    SALES_REPORT: "SALES_REPORT",
    STOCK_DETAILS_REPORT: "STOCK_DETAILS_REPORT",
    CUSTOMER_ACCOUNT_REPORT: "CUSTOMER_ACCOUNT_REPORT",
    VENDOR_ACCOUNT_REPORT: "VENDOR_ACCOUNT_REPORT",
    SALES_PURCHASE_COMPARISON_REPORT: "SALES_PURCHASE_COMPARISON_REPORT",
    ACCOUNT_LEDGER_REPORT: "ACCOUNT_LEDGER_REPORT",
    EXCEL_REPORT: "EXCEL_REPORT",
    SHOW_PURCHASE_PRICE: "SHOW_PURCHASE_PRICE",
};

export default Permissions;
