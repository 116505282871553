import React, {createContext, useState} from "react";
import {decryption} from "../helpers/Utils";
import Notification from "../components/common/Notification";
import POSProductServices from "../services/POSProductServices";

export const POSProductContext = createContext("POSProductContext");

const POSProductContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [productExcelLoading, setProductExcelLoading] = useState(false);
    const [companyLoading, setCompanyLoading] = useState(false);
    const [productEditLoading, setProductEditLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [searchProducts, setSearchProducts] = useState([]);
    const [loadingProductSearch, setLoadingProductSearch] = useState(false);
    const [posCompanyList, setPOSCompanyList] = useState([]);
    const [pagination] = useState({
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "30", "50", "100"],
    });

    const [totalElements, setTotalElements] = useState(0);
    const [products, setProducts] = useState([]);
    const [productsForSale, setProductsForSale] = useState([]);
    const [publicProducts, setPublicProducts] = useState(null);
    const [product, setProduct] = useState(null);

    const [requisitionCart, setRequisitionCart] = useState([]);

    const [totalCompanyProductElements, setTotalCompanyProductElements] = useState(0);
    const [companyProducts, setCompanyProducts] = useState([]);

    const [productInventoryLoading, setProductInventoryLoading] = useState(false);
    const [productInventoriesReport, setProductInventoriesReport] = useState([]);

    const [totalAvailableStock, setAvailableStock] = useState(0);

    async function getAllProductsFromOS(params) {
        try {
            setLoadingProductSearch(true);
            setErrorMsg("");

            const res = await POSProductServices.getAllProductsFromOS(params);

            const data = decryption(res.data.content);

            setSearchProducts(JSON.parse(data));
            setLoadingProductSearch(false);
        } catch (error) {
            setLoadingProductSearch(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllProductByCompanyId = async (params) => {
        try {
            setLoading(true);
            setErrorMsg("");

            const res = await POSProductServices.getAllProductByCompanyId(params);

            const data = decryption(res.data.content);

            setTotalCompanyProductElements(res.data.totalElements);

            setCompanyProducts(JSON.parse(data));

            setLoading(false);
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllPOSCompany = async params => {
        try {
            setCompanyLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.getAllPOSCompany(params);
            setPOSCompanyList(res.data.content);
            setCompanyLoading(false);
        } catch (error) {
            setCompanyLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    async function getAllRequiredProductsByShopId() {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.getAllRequiredProductsByShopId();

            setProducts(res.data);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }

    const getAllProductsByShopId = async (id, params) => {

        if (!params.isFortyPercent) {
            delete params.isFortyPercent;
        }

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.getAllProductsByShopId(id, params);
            setProducts(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return null;
        }
    };

    const getAllProductsForSaleByShopId = async (id, params) => {

        if (!params.isFortyPercent) {
            delete params.isFortyPercent;
        }

        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.getAllProductsForSaleByShopId(id, params);
            setProducts(res.data.content);
            setProductsForSale(res.data.content);
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return null;
        }
    };

    const getProductByShopIdById = async (shopId, id) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.getProductByShopIdById(
                shopId,
                id
            );
            setProduct(res.data);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };


    const getAllPublicProducts = async ({page, size, categorySlug, tagTitle}) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.getAllPublicProducts(
                page,
                size,
                categorySlug,
                tagTitle
            );
            setPublicProducts(res.data.content);
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const generateProductExcel = async (shopId) => {
        try {
            setProductExcelLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.generateProductExcel(shopId);
            if (res) {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', res.headers['content-disposition'].split("filename=")[1]);
                document.body.appendChild(link);
                link.click();
            }
            setProductExcelLoading(false);
            return true;
        } catch (error) {
            setProductExcelLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const addOrRemoveRequisitionCart = async (data) => {
        const _data = requisitionCart.find((e) => data.product.id === e.product.id);

        if (_data) {
            setRequisitionCart([
                ...requisitionCart.filter((e) => e.product.id !== data.product.id),
            ]);
            return;
        }
        setRequisitionCart([...requisitionCart, data]);
    };

    const existsInRequisitionCart = (id) => {
        const data = requisitionCart.find((e) => e.product.id === id);
        return data != null;
    };

    const changeRequisitionCart = (id, quantity) => {
        setRequisitionCart([]);
        if (isNaN(quantity)) {
            return;
        }
        setRequisitionCart([
            ...requisitionCart.map((e) => {
                if (e.product.id === id) {
                    e.product.quantity = quantity;
                }
                return e;
            }),
        ]);
    };

    const addProduct = async (shopId, data) => {
        try {

            setLoading(true);
            await POSProductServices.addProduct(shopId, data);
            Notification("success", "Product Created", "Successfully product created");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const updateProduct = async (shopId, id, data) => {
        try {

            setProductEditLoading(true);
            await POSProductServices.updateProduct(shopId, id, data);
            Notification("success", "Product Updated", "Successfully product updated");
            setProductEditLoading(false);
            return true;
        } catch (error) {
            setProductEditLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const deletePOSProduct = async (shopId, id) => {
        try {

            setLoading(true);
            await POSProductServices.deletePOSProduct(shopId, id);
            Notification("success", "Product Deleted", "Successfully product deleted");
            setLoading(false);
            return true;
        } catch (error) {
            setLoading(false);

            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }

    }

    const getProductByBarcode = async (barcode, shopId) => {
        try {
            setLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.getProductByBarcode(
                barcode,
                shopId
            );
            setProduct(res.data);
            setLoading(false);
            // return true;
            return res;
        } catch (error) {
            setLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            setErrorMsg(_error);
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    };

    const getProductInventoriesByShopId = async (shopId, params) => {
        try {
            setProductInventoryLoading(true);
            setErrorMsg("");
            const res = await POSProductServices.getAllProductInventoriesByShopId(shopId, params);

            setAvailableStock(res.headers?.count);

            setProductInventoriesReport(res.data);

            setProductInventoryLoading(false);
        } catch (error) {
            setProductInventoryLoading(false);
            let _error = "Something went wrong.";

            if (error.message === 'Network Error') {
                _error = error.message;
            }

            if (error.response && error.response.data) {
                _error = error.response.data.message;
            }
            Notification("error", "ERROR", `${_error}`);
            return false;
        }
    }


    return (
        <POSProductContext.Provider
            value={{
                loading,
                companyLoading,
                productExcelLoading,
                productEditLoading,
                loadingProductSearch,
                errorMsg,
                pagination,
                totalElements,
                totalCompanyProductElements,
                products,
                product,
                publicProducts,
                searchProducts,
                companyProducts,
                posCompanyList,
                getAllProductsByShopId,
                getProductByShopIdById,
                getAllPublicProducts,
                getAllProductsFromOS,
                getAllRequiredProductsByShopId,
                addOrRemoveRequisitionCart,
                existsInRequisitionCart,
                changeRequisitionCart,
                requisitionCart,
                getAllProductByCompanyId,
                generateProductExcel,
                updateProduct,
                addProduct,
                getAllPOSCompany,
                deletePOSProduct,
                setProducts,
                setProduct,
                getProductByBarcode,
                getAllProductsForSaleByShopId,
                productsForSale,
                getProductInventoriesByShopId,
                productInventoryLoading,
                productInventoriesReport,
                totalAvailableStock
            }}
        >
            {children}
        </POSProductContext.Provider>
    );
};

export default POSProductContextProvider;
