import React, {useContext, useEffect, useRef, useState} from 'react';
import {
    enumConverter,
    getDataFromNestedObject, getFracDigit,
    moneyFormatter,
    tableDateTimeFormatter
} from "../../../helpers/Utils";
import {useParams} from "react-router-dom";
import {Button, Modal, PageHeader} from "antd";
import {
    FacebookOutlined,
    LinkedinOutlined,
    PrinterOutlined,
    ShareAltOutlined,
    TwitterOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import PageWrapper from "../../common/PageWrapper";
import {POSSaleContext} from "../../../context/POSSaleContextProvider";
import SaleInvoicePrint from "../invoice/sale_invoice/SaleInvoicePrint";
// eslint-disable-next-line
import "../sale_history/sale_invoice.scss"
import ShareModal from "../../common/ShareModal";
import {IMAGE_URL} from "../../../helpers/Constants";
import numberToWords from 'number-to-words';

const PosSaleHistoryPrint = () => {

    const params = useParams();
    const {id, shopId} = params;

    const posSaleContext = useContext(POSSaleContext);

    // const posConfigurationContext = useContext(POSConfigurationContext);

    const saleHistoryDetailsPrintRef = useRef(null);
    const [saleHistory, setSaleHistory] = useState(null);
    const currentURL = window.location.href;

    const [shareModal, setShareModal] = useState(false);

    const socialIconList = [
        {
            id: 1,
            name: "Facebook",
            path: `fb-messenger://share?link=${currentURL}`,
            icon: <FacebookOutlined style={{fontSize: "50px", color: "#2973EA"}}/>
        },
        {
            id: 4,
            name: "Twitter",
            path: `https://twitter.com/intent/tweet?text=${currentURL}`,
            icon: <TwitterOutlined style={{fontSize: "50px", color: "#3396E8"}}/>
        },
        {
            id: 5,
            name: "Linkedin",
            path: `https://www.linkedin.com/shareArticle?mini=true&url=${currentURL}`,
            icon: <LinkedinOutlined style={{fontSize: "50px", color: "#2573B1"}}/>
        },
        {
            id: 6,
            name: "Whatsapp",
            path: `https://web.whatsapp.com/send?text=${currentURL}`,
            icon: <WhatsAppOutlined style={{fontSize: "50px", color: "#40D03F"}}/>
        },
    ]

    const openShareModal = () => {
        setShareModal(true);
    }

    const closeShareModal = () => {
        setShareModal(false);
    }

    useEffect(() => {
        posSaleContext.getSaleReceipt(id, shopId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPDF = () => {
        setSaleHistory(posSaleContext?.saleReceiptData);
        setTimeout(() => {
            if (saleHistoryDetailsPrintRef) {
                saleHistoryDetailsPrintRef.current.refHandlePrint();
            }
        }, 100);
    }

    const columns = [
        {
            title: <b>SL.</b>,
            key: 'slNo',
            render: (e, index) => (
                <div className='print-text'>
                    {index + 1}
                </div>
            )
        },
        {
            title: <b>Name</b>,
            key: 'name',
            render: e => e?.productName
        },
        {
            title: <b>Price(Pcs)</b>,
            key: 'unitPrice',
            render: e => (
                <div className='print-price'>
                    {e.salePrice?.toFixed(getFracDigit())}
                </div>
            )
        },
        {
            title: <b>Qty</b>,
            key: 'quantity',
            render: e => (
                <div className='print-text'>
                    {e.quantity?.toFixed(getFracDigit())}
                </div>
            )
        },
        {
            title: <b>Total</b>,
            key: 'totalSalePrice',
            render: e => (
                <div className='print-text'>
                    {e.totalSalePrice?.toFixed(getFracDigit())}
                </div>
            )
        }
    ];

    const getPageMargins = () => {
        return `@page {
                    margin: 0px !important;
                 }
                 @media print {
                     .page-break {
                        page-break-before: always;
                    }
                }`;
    };

    const pageHeader = <PageHeader
        title={
            <>
            </>
        }
    />;

    return (
        <PageWrapper pageHeader={pageHeader}>
            <div
                className="sale-invoice"
                style={{
                    padding: '0 50px'
                }}
            >
                <div className='button-section'>
                    <div className='button-child'>
                        <Button
                            className='print-button'
                            size="small"
                            shape="round"
                            icon={<PrinterOutlined/>}
                            onClick={() => getPDF()}
                        >
                            Print
                        </Button>

                        <Button
                            className='print-button'
                            size="small"
                            shape="round"
                            icon={<ShareAltOutlined/>}
                            onClick={openShareModal}
                        >
                            Share
                        </Button>

                        <div>
                        </div>
                    </div>
                </div>

                <style>{getPageMargins()}</style>

                <style>{getPageMargins()}</style>

                {
                    posSaleContext?.saleReceiptData?.saleDetailsList?.length > 0 ?
                        <div>

                            {
                                posSaleContext.saleReceiptData?.configLogo &&
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    marginBottom: '10px'
                                }}>
                                    <img
                                        src={`${IMAGE_URL}${posSaleContext.saleReceiptData?.logo}?dummy=${new Date().getMilliseconds()}`}
                                        alt="avatar"
                                        // style={{height: "60px"}}
                                        className='sale-print-logo'
                                    />
                                </div>
                            }

                            {
                                posSaleContext?.saleReceiptData?.topTextBold?.split("\n")?.map((v, index) =>
                                    <p key={index} className="header-p-bold" style={{}}>
                                        {v}
                                    </p>
                                )
                            }
                            {
                                posSaleContext?.saleReceiptData?.topText?.split("\n")?.map((v, index) =>
                                    <p key={index} className="header-p">
                                        {v}
                                    </p>
                                )
                            }


                            <div className="sale-info-sale-print">

                                {
                                    posSaleContext?.saleReceiptData?.servedByRow ?
                                        <div className='main'>
                                            <b className='heading'>
                                                Served By -
                                            </b>
                                            <span className='heading'>
                                        {
                                            posSaleContext?.saleReceiptData?.userFullName && posSaleContext?.saleReceiptData?.userFullName
                                        }
                                            </span>
                                        </div>
                                        : null
                                }

                                <div className='main'>
                                    <b className='heading'>
                                        Sales No -
                                    </b>
                                    <span className='heading'>
                                        {
                                            posSaleContext?.saleReceiptData?.saleNumber?.substring(posSaleContext.saleReceiptData?.saleNumber?.length - 11)
                                        }
                                    </span>
                                </div>

                                {
                                    posSaleContext.saleReceiptData?.chalanNumber &&
                                    <div className='main'>
                                        <b className='heading'>
                                            Chalan No -
                                        </b>
                                        <span className='heading'>
                                        {
                                            posSaleContext.saleReceiptData?.chalanNumber
                                        }
                                    </span>
                                    </div>
                                }
                                <div
                                    className='main'
                                    style={{
                                        borderBottom: '1px solid #DDDDDD',
                                        paddingBottom: '5px'
                                    }}
                                >
                                    <b
                                        className='heading'
                                    >
                                        Date & Time -
                                    </b>
                                    <span className='heading'>
                                        {
                                            tableDateTimeFormatter(posSaleContext?.saleReceiptData.createdAt)
                                        }
                                    </span>
                                </div>

                                {/*<Divider style={{*/}
                                {/*    padding: '0',*/}
                                {/*    width: '100px'*/}
                                {/*}}/>*/}

                                {
                                    posSaleContext?.saleReceiptData?.configCustomerName ?

                                        <div className='main'
                                        >
                                            <b className='heading'>
                                                Bill To -
                                            </b>
                                            <span className='heading'>
                                        {
                                            posSaleContext.saleReceiptData?.customerName && posSaleContext.saleReceiptData?.customerName
                                        }
                                    </span>
                                        </div>
                                        : null
                                }

                                {
                                    posSaleContext?.saleReceiptData?.configCustomerPhoneNumber ?

                                        <div className='main'>
                                            <b className='heading'>
                                                Phone -
                                            </b>
                                            <span className='heading'>
                                        {
                                            posSaleContext.saleReceiptData?.customerPhoneNumber && posSaleContext.saleReceiptData?.customerPhoneNumber
                                        }
                                            </span>
                                        </div>
                                        : null
                                }

                                {
                                    posSaleContext?.saleReceiptData?.configCustomerAddress ?
                                        <div className='main'>
                                            <b className='heading'>
                                                Address -
                                            </b>
                                            <span className='heading'>
                                        {
                                            posSaleContext?.saleReceiptData?.customerAddress && posSaleContext?.saleReceiptData?.customerAddress
                                        }
                                            </span>
                                        </div>
                                        : null
                                }

                            </div>
                        </div>
                        :
                        <></>
                }


                {/*<div ref={saleInvoiceTable}>*/}


                <div

                    // Watermark
                    // style={{
                    //     position: 'relative',
                    //     width: '100%',
                    //     height: '100%',
                    //     // backgroundColor: 'red',
                    //     background: `url(${IMAGE_URL}${posSaleContext.saleReceiptData?.logo}?dummy=${new Date().getMilliseconds()}) center center no-repeat`,
                    //     backgroundSize: '100%',
                    // }}
                >
                    <table>
                        <thead>
                        <tr>
                            {
                                columns?.map(column => <td
                                        style={{textAlign: "center"}}
                                        key={column.key}
                                    >
                                        {column.title}
                                    </td>
                                )
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            posSaleContext.saleReceiptData?.saleDetailsList && posSaleContext?.saleReceiptData.saleDetailsList?.map((stockDetails, index) => (
                                <tr key={index}>
                                    {
                                        columns?.map(column => {
                                            return (
                                                <td key={column.key}>
                                                    {
                                                        column.render ? column.render(stockDetails, index) :
                                                            getDataFromNestedObject(stockDetails, column.dataIndex)
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            ))
                        }

                        </tbody>
                    </table>
                </div>

                <div
                >
                    <table>

                        {
                            posSaleContext?.saleReceiptData?.saleDetailsList?.length > 0 ?
                                <tr>
                                    <td colSpan={columns?.length} style={{border: "1px solid #fff"}}>
                                        <div className="table-footer">
                                            <div className="table-footer-content">
                                                <p style={{
                                                    fontWeight: 'bold'
                                                }}>
                                                    <b>Total</b>
                                                    <span
                                                        className="bordered-span">{
                                                        moneyFormatter(posSaleContext?.saleReceiptData.totalPrice?.toFixed(getFracDigit()))
                                                    }</span>
                                                </p>


                                                {
                                                    !posSaleContext?.saleReceiptData?.simplePrint &&
                                                    <div>
                                                        {
                                                            posSaleContext?.saleReceiptData?.cardFeeRow ? <p>
                                                                <b>Vat</b>
                                                                <span
                                                                    className="normal-span">{moneyFormatter(posSaleContext?.saleReceiptData.vatAmount?.toFixed(getFracDigit()))}</span>
                                                            </p> : null
                                                        }
                                                        {/*<p>*/}
                                                        {/*    <b>Less</b>*/}
                                                        {/*    <span*/}
                                                        {/*        className="normal-span">-&nbsp;{posSaleContext?.saleReceiptData.nettingAmount.toFixed(getFracDigit())}</span>*/}
                                                        {/*</p>*/}

                                                        {
                                                            posSaleContext?.saleReceiptData?.discountRow ? <div>
                                                                {
                                                                    posSaleContext?.saleReceiptData.discountType ?
                                                                        <p>
                                                                            {
                                                                                (!posSaleContext?.saleReceiptData.discountType || posSaleContext?.saleReceiptData.discountType === "FLAT") &&
                                                                                <b>Discount</b>
                                                                            }
                                                                            {
                                                                                (posSaleContext?.saleReceiptData.discountType === "PERCENTAGE") &&
                                                                                <b>Discount
                                                                                    ({posSaleContext?.saleReceiptData.discountPercent}%)</b>
                                                                            }
                                                                            <span className="normal-span">-&nbsp;
                                                                                {moneyFormatter(posSaleContext?.saleReceiptData.discount?.toFixed(getFracDigit()))}
                                                                            </span>
                                                                        </p> :
                                                                        <p>
                                                                            <b>Discount</b>
                                                                            <span
                                                                                className="normal-span">{moneyFormatter(posSaleContext?.saleReceiptData.discountAmount)}</span>
                                                                        </p>
                                                                }
                                                            </div> : null
                                                        }

                                                        <p style={{marginBottom: '10px'}}>
                                                            <b>Net Amount(TK)</b>
                                                            <span
                                                                className="bordered-span"
                                                                style={{
                                                                    fontWeight: 'bold'
                                                                }}
                                                            >{moneyFormatter(posSaleContext?.saleReceiptData.finalPrice?.toFixed(getFracDigit()))}</span>
                                                        </p>

                                                        {
                                                            posSaleContext?.saleReceiptData.payments?.length > 0 ? posSaleContext?.saleReceiptData.payments?.map(payment =>
                                                                    <p key={payment.paymentType?.name}>
                                                                        <b>{enumConverter(payment.paymentType?.name)}</b>
                                                                        <span
                                                                            className="normal-span">{payment.amount?.toFixed(getFracDigit())}</span>
                                                                    </p>
                                                                ) :
                                                                <>
                                                                    <p>
                                                                        <b>{posSaleContext.saleReceiptData?.paymentType?.name}</b>
                                                                        <span className="normal-span">
                                                                            {moneyFormatter(posSaleContext?.saleReceiptData.paidAmount?.toFixed(getFracDigit()))}
                                                                        </span>
                                                                    </p>
                                                                </>
                                                        }
                                                        <p style={{marginBottom: '10px', fontWeight: 'bold'}}>
                                                            <b>Total Paid(TK)</b>
                                                            <span
                                                                className="bordered-span">
                                                                {moneyFormatter(posSaleContext?.saleReceiptData.paidAmount?.toFixed(getFracDigit()))}
                                                            </span>
                                                        </p>

                                                        {
                                                            posSaleContext?.saleReceiptData?.totalPaidInWord &&
                                                            <p style={{marginBottom: '10px', fontWeight: 'bold'}}>
                                                                <b>Total Paid In Word</b>
                                                                <span
                                                                    className="bordered-span-down">
                                                                {
                                                                    numberToWords.toWords(posSaleContext?.saleReceiptData.paidAmount).toUpperCase() + ' TAKA ONLY'
                                                                }
                                                            </span>
                                                            </p>
                                                        }

                                                        <p>
                                                            <b>Due</b>
                                                            <span
                                                                className="normal-span">{moneyFormatter(posSaleContext?.saleReceiptData.dueAmount?.toFixed(getFracDigit()))}</span>
                                                        </p>
                                                    </div>
                                                }
                                            </div>


                                            {
                                                (posSaleContext?.saleReceiptData?.customerSignature || posSaleContext?.saleReceiptData?.ownerSignature) &&
                                                <div style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    padding: '80px 80px 20px 80px'
                                                }}>
                                                    {
                                                        posSaleContext?.saleReceiptData?.customerSignature &&
                                                        <div style={{
                                                            borderTop: '1px solid black',
                                                            paddingBottom: '5px'
                                                        }}>
                                                            {posSaleContext?.saleReceiptData?.customerSignature}
                                                        </div>
                                                    }

                                                    {
                                                        posSaleContext?.saleReceiptData?.ownerSignature &&
                                                        <div style={{
                                                            borderTop: '1px solid black',
                                                            paddingBottom: '5px'
                                                        }}>
                                                            {posSaleContext?.saleReceiptData?.ownerSignature}
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            <div className='additional-info' style={{border: 'none'}}>
                                                {
                                                    posSaleContext?.saleReceiptData?.secondBottomText ? posSaleContext?.saleReceiptData?.secondBottomText?.split("\n")?.map((v, index) =>
                                                        <p key={index} style={{marginBottom: "5px"}}>
                                                            {v}
                                                        </p>
                                                    ) : null
                                                }
                                            </div>

                                            <div
                                                className={`${posSaleContext?.saleReceiptData?.bottomText !== '' ? 'additional-info' : ''}`}>
                                                {
                                                    posSaleContext?.saleReceiptData?.bottomText ? posSaleContext?.saleReceiptData?.bottomText?.split("\n")?.map((v, index) =>
                                                        <p key={index} style={{marginBottom: "5px"}}>
                                                            {v}
                                                        </p>
                                                    ) : null
                                                }
                                            </div>

                                            <p className="additional-info" style={{border: 'none'}}>
                                                Get Your Desired Solution | Visit zaagsys.com (01814981719)
                                            </p>
                                        </div>
                                    </td>
                                </tr>
                                :
                                <></>
                        }

                    </table>
                </div>

                {
                    saleHistory ? <SaleInvoicePrint
                        ref={saleHistoryDetailsPrintRef}
                        data={saleHistory}
                        pdfConfig={saleHistory}
                        // pdfConfig={posConfigurationContext.printingConfig}
                    /> : <></>
                }

                <Modal
                    title={"Share Your Property"}
                    visible={shareModal}
                    onCancel={closeShareModal}
                    width={390}
                    footer={null}
                >
                    <ShareModal
                        socialIconList={socialIconList}
                        copyLink={currentURL}
                    />
                </Modal>
            </div>
        </PageWrapper>
    );
};

export default PosSaleHistoryPrint;