import axios from "axios";
import * as URL from "../helpers/Constants";
import {getShopId} from "../helpers/Utils";

export default class POSProductServices {

    static getAllProductsByShopId(id, params) {

        if (params.inventoryCreated === undefined || params.inventoryCreated === null) {
            delete params.inventoryCreated;
        }

        return axios.get(`${URL.POS_GET_ALL_PRODUCT_BY_SHOP_ID}/${id}`, {
            ...POSProductServices.getAuthHeader(),
            params,
            paramsSerializer: (params) => {
                let result = '';
                Object.keys(params).forEach(key => {


                    result += `${key}=${encodeURIComponent(params[key])}&`;
                });
                return result.substr(0, result.length - 1);
            }
        });
    }

    static getAllProductsForSaleByShopId(id, params) {

        if (params.inventoryCreated === undefined || params.inventoryCreated === null) {
            delete params.inventoryCreated;
        }

        return axios.get(`${URL.POS_GET_ALL_PRODUCT_FOR_SALE_BY_SHOP_ID}/${id}`, {
            ...POSProductServices.getAuthHeader(),
            params,
            paramsSerializer: (params) => {
                let result = '';
                Object.keys(params).forEach(key => {


                    result += `${key}=${encodeURIComponent(params[key])}&`;
                });
                return result.substr(0, result.length - 1);
            }
        });
    }

    static getAllRequiredProductsByShopId() {
        return axios.get(`${URL.POS_GET_ALL_REQUIRED_PRODUCTS}/${getShopId()}`, {
            ...POSProductServices.getAuthHeader(),
        });
    }

    static getAllPublicProducts(page, size, id, categorySlug, tagTitle) {
        return axios.get(`${URL.POS_GET_ALL_PUBLIC_PRODUCT}/`, {
            ...POSProductServices.getAuthHeader(),
            params: {page, size, categorySlug, tagTitle},
        });
    }

    static getProductByShopIdById(shopId, id) {
        return axios.get(
            `${URL.POS_GET_PRODUCT_BY_SHOP_ID_BY_ID}/${shopId}/${id}`,
            POSProductServices.getAuthHeader()
        );
    }

    static getAllProductsFromOS(params) {
        return axios.get(`${URL.OS_SEARCH_PRODUCTS_URL}`, {
            ...POSProductServices.getAuthHeader(),
            params,
        });
    }

    static getAllProductByCompanyId(params) {
        return axios.get(`${URL.POS_ALL_PRODUCT_BY_COMPANY_ID}`, {
            ...POSProductServices.getAuthHeader(),
            params,
        });
    }

    static getAllPOSCompany(params) {
        return axios.get(URL.GET_ALL_POS_COMPANIES, {
            ...POSProductServices.getAuthHeader(),
            params
        });
    }

    static generateProductExcel(shopId) {
        return axios({
            url: `${URL.GENERATE_PRODUCT_EXCEL}/${shopId}`,
            method: 'GET',
            responseType: 'blob',
            ...POSProductServices.getAuthHeader()
        });
    }

    static addProduct(shopId, data) {
        return axios.post(`${URL.POS_CREATE_PRODUCT}/${shopId}`, data, POSProductServices.getAuthHeader());
    }

    static updateProduct(shopId, id, data) {
        return axios.put(`${URL.POS_UPDATE_PRODUCT}/${shopId}/${id}`, data, POSProductServices.getAuthHeader());
    }

    static deletePOSProduct(shopId, id) {
        return axios.delete(`${URL.DELETE_POS_PRODUCT}/${shopId}/${id}`, POSProductServices.getAuthHeader());
    }

    static getProductByBarcode(barcode, shopId) {
        return axios.get(`${URL.POS_GET_PRODUCT_BY_BARCODE}/${barcode}/${shopId}`, {
            ...POSProductServices.getAuthHeader(),
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {Authorization: `Bearer ${accessToken}`}};
    }

    static getAllProductInventoriesByShopId(shopId, params) {
        return axios.get(`${URL.POS_GET_ALL_PRODUCT_INVENTORIES}/${shopId}`, {
            ...POSProductServices.getAuthHeader(),
            params,
        });
    }

}
