import React from 'react';
import {toWords} from "number-to-words";
import {
    formatter,
    getCurrency,
    getImagePath,
    getRemainingPayment,
    moneyFormatter,
    paymentStatus,
    tableDateTimeFormatter
} from "../../../../helpers/Utils";
import {discountPercentage} from "../../../../helpers/Constants";

import "./pdf_table_content.scss";

const PDFTableContent = ({pdfConfig, isEmptyCell = true}) => {

    const generateEmptyCell = () => {

        let emptyRow;
        const totalPageRow = 31;
        const exactPageRwo = 19;

        const totalElements = pdfConfig?.saleDetailsList?.length;

        const mod = totalElements % totalPageRow;

        if (mod > exactPageRwo) {
            emptyRow = (totalPageRow - mod) + exactPageRwo;
        } else if (mod < exactPageRwo) {
            emptyRow = exactPageRwo - mod;
        } else {
            return;
        }


        return Array.from({length: emptyRow}, (_, index) => {

            return <tr key={`e-${index}`} className="empty-cell">
                <td colSpan={6} style={{height: "33.39px"}}>&nbsp;</td>
            </tr>
        });
    }

    const nameColClass = "double-name-col";

    const colSpan = 6;

    const productInfoRow = (sl, data) => {
        return <tr key={sl}>
            <td className="product-info-sl-td">
                <div className="sl-col">
                    {sl}
                </div>
            </td>

            {
                pdfConfig?.productName ? <td className="product-product-name-td">
                    <div className={nameColClass}>
                        {pdfConfig?.productName ? data.productName : ""}
                    </div>
                </td> : null
            }

            {
                pdfConfig?.displayName ? <td className="product-display-name-td">
                    <div className={nameColClass}>
                        {pdfConfig?.displayName ? data.displayName : ""}
                    </div>
                </td> : null
            }

            <td className="quantity-td">
                <div className="quantity-col">
                    {data.quantity}
                </div>
            </td>

            <td className="price-td">
                <div className="price-col">
                    {data.salePrice}
                </div>
            </td>

            <td className="total-td">
                <div className="total-col">
                    {data.totalSalePrice}
                </div>
            </td>
        </tr>;
    }

    const brandingHeader = () => {
        return <div
            className="branding-header"
        >
            <div className="logo">
                <img
                    src={getImagePath(pdfConfig?.logo)}
                    alt="avatar"
                    className='sale-a4-image'
                />
            </div>

            <div
                className="company-info"
            >
                <h1 className="company-name single-line-ellipsis">
                    {pdfConfig?.topTextBold}
                </h1>
                <h4 className="m-b-8 m-t-10 double-line-ellipsis">
                    {pdfConfig?.topText}
                </h4>
                <h4 className="single-line-ellipsis">
                    {pdfConfig?.topLeftText}
                </h4>
                <h4 className="m-b-10 m-t-8 single-line-ellipsis">
                    {pdfConfig?.topRightText}
                </h4>
            </div>
        </div>;
    }

    const invoiceDetailsHeader = () => {
        return <div className="invoice-details-header">
            <div className="invoice-info">
                <p className="info-row">
                    <b>Invoice No:</b> <span>{pdfConfig?.saleNumber}</span>
                </p>
                <p className="info-row">
                    <b>Sale Date:</b> <span>{tableDateTimeFormatter(pdfConfig?.createdAt)}</span>
                </p>
                <p className="info-row">
                    <b>Served By:</b> <span>{pdfConfig?.userFullName}</span>
                </p>
                <p className="info-row">
                    <b>Payment Status:</b> {paymentStatus(pdfConfig?.dueAmount, "summary-heading")}
                </p>
            </div>
            <div className="customer-info">
                <p className="info-row">
                    <b>Customer Name:</b> <span>{pdfConfig?.customerName}</span>
                </p>
                <p className="info-row">
                    <b>Customer Number:</b> <span>{pdfConfig?.customerPhoneNumber}</span>
                </p>
                <p className="info-row">
                    <b>Customer Address:</b> <span>{pdfConfig?.customerAddress}</span>
                </p>
                <p className="info-row">
                    <b>Customer Balance:</b> <span
                    style={{color: getRemainingPayment(pdfConfig?.customerBalance).color}}>
                        {moneyFormatter(getRemainingPayment(pdfConfig?.customerBalance).amount)}
                    {
                        pdfConfig?.customerBalance &&
                        <span> ({getRemainingPayment(pdfConfig?.customerBalance).status})</span>
                    }
                    </span>
                </p>
            </div>
        </div>;
    }

    const getAmountDecimalInWord = () => {

        const fractionalAmount = ((pdfConfig?.paidAmount ? pdfConfig?.paidAmount : 0) % 1) * 100;

        const _toWords = fractionalAmount > 0 ? `and ${toWords(formatter.format(fractionalAmount))}` : ""

        return _toWords;

    }

    const priceSummary = () => {
        return <div className="price-summary">
            <div className="in-word">
                {
                    pdfConfig?.totalPaidInWord ? <>
                        <h2>Total Paid in Words</h2>
                        <h3>
                            {toWords(pdfConfig?.paidAmount ? pdfConfig?.paidAmount : 0)} {getAmountDecimalInWord()} {getCurrency()}
                        </h3>
                    </> : null
                }
            </div>
            <div className="summary">
                <p className="price-row">
                    <b>Sub Total</b><span>{moneyFormatter(pdfConfig?.totalPrice)}</span>
                </p>
                <p className="price-row">
                    <b>Discount</b><span>{pdfConfig?.discountType === discountPercentage ? `(${pdfConfig?.discountPercent}%)` : ""}</span>
                </p>
                <p className="price-row">
                    <b>Vat</b><span>{moneyFormatter(pdfConfig?.vatAmount)}</span>
                </p>
                <p className="price-row">
                    <b>Final Price</b><span>{moneyFormatter(pdfConfig?.finalPrice)}</span>
                </p>
                <p className="price-row">
                    <b>Total Paid</b><span>{moneyFormatter(pdfConfig?.paidAmount)}</span>
                </p>
                <p className="price-row">
                    <b>Due to Paid</b><span>{moneyFormatter(pdfConfig?.dueAmount)}</span>
                </p>
            </div>
        </div>;
    }

    return (
        <table
            className="pdf-table"
        >
            <thead>
            <tr className="branding-header-tr">
                <td colSpan={colSpan}>
                    {brandingHeader()}
                </td>
            </tr>
            <tr className="invoice-details-header-tr">
                <td colSpan={colSpan}>
                    {invoiceDetailsHeader()}
                </td>
            </tr>
            <tr className="bold">
                <td className="sl-td-header">SL.</td>
                {
                    pdfConfig?.productName ? <td className="product-name-td-header">
                        {pdfConfig?.productName ? pdfConfig?.productNameText : ""}
                    </td> : null
                }
                {
                    pdfConfig?.displayName ? <td className="display-name-td-header">
                        {pdfConfig?.displayName ? pdfConfig?.displayNameText : ""}
                    </td> : null
                }
                <td className="quantity-td-header">Qty</td>
                <td className="price-td-header">Price</td>
                <td className="total-td-header">Total</td>
            </tr>
            </thead>
            <tbody>
            {
                pdfConfig?.saleDetailsList?.map((data, i) => productInfoRow(i + 1, data))
            }
            {
                isEmptyCell ? generateEmptyCell() : null
            }
            <tr>
                <td colSpan={colSpan}>
                    {priceSummary()}
                </td>
            </tr>
            <tr>
                <td colSpan={colSpan}>
                    <div>
                        <div className="signature-content">
                            <div className="left-signature">
                                {pdfConfig?.customerSignature}
                            </div>
                            <div className="right-signature">
                                {pdfConfig?.ownerSignature}
                            </div>
                        </div>
                        <div className="footer-info">
                            <a href={pdfConfig?.secondBottomText}>
                                {pdfConfig?.secondBottomText}
                            </a>
                            <p style={{display: "flex", flexDirection: "column"}}>
                                <span>{pdfConfig?.bottomText}</span>
                            </p>
                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    );
};

export default PDFTableContent;