const version = "1.0.0";
const pos_version = "1.0.0";

//AUTH
const {REACT_APP_AUTH_BASE_URL} = process.env;
const {REACT_APP_IMAGE_AUTH_URL} = process.env;

//POS
const {REACT_APP_POS_BASE_URL} = process.env;
const {REACT_APP_IMAGE_POS_URL} = process.env;

// AUTH URL
export const ROOT_URL = `${REACT_APP_AUTH_BASE_URL}/api/v/${version}`;
export const IMAGE_URL = `${REACT_APP_IMAGE_AUTH_URL}/api/v/${version}`;

//POS URL
export const POS_ROOT_URL = `${REACT_APP_POS_BASE_URL}/api/v/${pos_version}`;
export const POS_IMAGE_URL = `${REACT_APP_IMAGE_POS_URL}/api/v/${pos_version}`;

export const PUBLIC_API_URL = `${ROOT_URL}/public`;
export const SECURED_API_URL = `${ROOT_URL}/secured`;
export const POS_SECURED_API_URL = `${POS_ROOT_URL}/secured`;

// image
export const IMAGE_UPLOAD = `${SECURED_API_URL}/image/upload`;

//user
export const SIGN_IN = `${PUBLIC_API_URL}/users/login`;
export const SALES_PERSON_SIGN_IN = `${PUBLIC_API_URL}/sales-person/login`;

export const GET_USER_PROFILE = `${SECURED_API_URL}/users/profile`;

//dashboard
export const GET_ALL_ACTIVE_SHOPS = `${SECURED_API_URL}/dashboards/active-shops`;
export const GET_ACTIVE_SHOP_COUNT = `${SECURED_API_URL}/dashboards/shops/active/count`;
export const GET_ALL_CUSTOMERS_COUNT = `${SECURED_API_URL}/dashboards/customers/total/count`;
export const GET_ALL_PENDING_ORDER_COUNT = `${SECURED_API_URL}/dashboards/orders/pending/count`;
export const GET_ALL_DELIVERY_PERSONS_COUNT = `${SECURED_API_URL}/dashboards/delivery-persons/active/count`;

//picture upload
export const PROFILE_PICTURE_UPLOAD_PATH = `${SECURED_API_URL}/profile-pic/upload`;
export const NID_FRONT_PICTURE_UPLOAD_PATH = `${SECURED_API_URL}/nid-front-pic/upload`;
export const NID_BACK_PICTURE_UPLOAD_PATH = `${SECURED_API_URL}/nid-back-pic/upload`;

export const PRODUCT_PICTURE_UPLOAD = `${SECURED_API_URL}/product-pic/upload`;

//pos_shop logo upload
export const SHOP_PIC_UPLOAD_URL = `${SECURED_API_URL}/shop-pic/upload`;
export const BANNER_PIC_UPLOAD_URL = `${SECURED_API_URL}/banner-pic/upload`;
export const SHOP_PRODUCT_IMAGE_UPLOAD_URL = `${SECURED_API_URL}/product-pic/upload`;

//item picture upload
export const ITEM_PICTURE_UPLOAD_URL = `${SECURED_API_URL}/item-pic/upload`;

//user
export const GET_ALL_USERS = `${SECURED_API_URL}/users`;
export const GET_USER_BY_ID = `${SECURED_API_URL}/users/id`;
export const POST_USER = `${SECURED_API_URL}/users/create`;
export const UPDATE_USER = `${SECURED_API_URL}/users/update`;
export const UPDATE_PROFILE = `${SECURED_API_URL}/users/profile`;
export const DELETE_USER = `${SECURED_API_URL}/users/delete`;
export const CHANGE_ROLE = `${SECURED_API_URL}/users/change-role`;
export const USER_IMAGE_UPLOAD = `${SECURED_API_URL}/user-image/upload`;

//role
export const GET_ALL_ROLES = `${SECURED_API_URL}/users/roles`;
export const ADD_ROLE = `${SECURED_API_URL}/users/create-role`;
export const UPDATE_ROLE = `${SECURED_API_URL}/users/update-role`;
export const DELETE_ROLE = `${SECURED_API_URL}/users/delete-role`;
export const GET_ROLE_BY_ID = `${SECURED_API_URL}/users/roles/id`;

// shop role
export const GET_SHOP_ROLE_BY_ID = `${SECURED_API_URL}/users/roles-by/id`;
export const ADD_SHOP_OWNER_ROLE = `${SECURED_API_URL}/shop-owners/create-role`;
export const EDIT_SHOP_OWNER_ROLE = `${SECURED_API_URL}/shop-owners/update-role`;

//banner
export const GET_ALL_BANNER = `${SECURED_API_URL}/banners`;
export const GET_BANNER_BY_ID = `${SECURED_API_URL}/banners/id`;
export const ADD_BANNER = `${SECURED_API_URL}/banners/create`;
export const UPDATE_BANNER = `${SECURED_API_URL}/banners/update`;
export const DELETE_BANNER = `${SECURED_API_URL}/banners/delete`;

//Product type
export const GET_ALL_PRODUCT_TYPE_URL = `${POS_SECURED_API_URL}/product-types/all/for-admin`;
export const GET_PRODUCT_TYPE_BY_ID_URL = `${POS_SECURED_API_URL}/product-types/for-admin`;
export const ADD_PRODUCT_TYPE_URL_URL = `${POS_SECURED_API_URL}/product-types/create/for-admin`;
export const UPDATE_PRODUCT_TYPE_URL = `${POS_SECURED_API_URL}/product-types/update/for-admin`;
export const DELETE_PRODUCT_TYPE_URL = `${POS_SECURED_API_URL}/product-types/delete/for-admin`;

//company
export const GET_ALL_ADMIN_COMPANIES = `${POS_SECURED_API_URL}/shop-companies/all/for-admin`;
export const GET_ADMIN_COMPANY_BY_ID = `${POS_SECURED_API_URL}/shop-companies/for-admin`;
export const UPDATE_ADMIN_COMPANY = `${POS_SECURED_API_URL}/shop-companies/update/for-admin`;
export const POST_ADMIN_COMPANY = `${POS_SECURED_API_URL}/shop-companies/create/for-admin`;
export const DELETE_ADMIN_COMPANY = `${POS_SECURED_API_URL}/shop-companies/delete/for-admin`;

//permissions
export const GET_ALL_PERMISSIONS = `${SECURED_API_URL}/users/permissions`;
export const GET_ALL_POS_SHOP_PERMISSIONS = `${SECURED_API_URL}/shop-owners/pos/permission`;
export const GET_PERMISSIONS_BY_USER_TYPE = `${SECURED_API_URL}/users/permissions/user-type`;

//pos_shop
export const GET_ALL_SHOPS = `${SECURED_API_URL}/shops`;
export const GET_SHOP_BY_ID = `${SECURED_API_URL}/shops/id`;
export const POST_SHOP = `${SECURED_API_URL}/shops/create`;
export const UPDATE_SHOP = `${SECURED_API_URL}/shops/update`;
export const CHECK_SHOP_AS_ONLINE = `${SECURED_API_URL}/shops/os/check-as-online`;
export const DELETE_SHOP = `${SECURED_API_URL}/users/permissions`;
export const GET_SUBSCRIPTION_PLAN_FOR_SHOP = `${SECURED_API_URL}/shops/subscription-plan`;
export const GET_SHOP_ROLES_BY_ID = `${SECURED_API_URL}/users/roles/shop`;
export const GET_SHOP_STATISTICS_URL = `${POS_SECURED_API_URL}/reports/shop/latest/data/creation-report`;

// POS ROLE
export const GET_POS_SHOP_ROLES_BY_ID = `${SECURED_API_URL}/users/roles-for-shop`;

//company
export const GET_ALL_COMPANIES = `${SECURED_API_URL}/companies`;
export const GET_COMPANY_BY_ID = `${SECURED_API_URL}/companies/id`;
export const UPDATE_COMPANY = `${SECURED_API_URL}/companies/update`;
export const POST_COMPANY = `${SECURED_API_URL}/companies/create`;
export const DELETE_COMPANY = `${SECURED_API_URL}/companies/delete`;

//pos_shop owner
export const GET_ALL_SHOP_OWNERS = `${GET_ALL_USERS}?userType=SHOP_OWNER`;
export const POST_SHOP_OWNER = `${SECURED_API_URL}/shop-owners/create`;
export const UPDATE_SHOP_OWNER = `${SECURED_API_URL}/shop-owners/update`;
export const DELETE_SHOP_OWNER = `${SECURED_API_URL}/shop-owners/delete`;
export const POST_SALES_PERSON_FROM_SHOP_OWNER = `${SECURED_API_URL}/shop-owners/sales-person/create`;
export const UPDATE_SALES_PERSON_FROM_SHOP_OWNER = `${SECURED_API_URL}/shop-owners/sales-person/update`;
export const POST_PRODUCT_REQUEST = `${SECURED_API_URL}/shop-owners/product/request`;
export const GET_ALL_PRODUCTS_FOR_SHOP_OWNER = `${SECURED_API_URL}/shop-owners/product/all`;
export const GET_ALL_SHOP_FOR_SHOP_OWNER = `${SECURED_API_URL}/shop-owners/shops/lite`;

//pos_sales_person
export const GET_ALL_SALES_PERSON = `${SECURED_API_URL}/sales-person`;
export const GET_SALES_PERSON_BY_ID = `${SECURED_API_URL}/sales-person/id`;
export const POST_SALES_PERSON_FROM_ADMIN = `${SECURED_API_URL}/sales-person/create`;
export const UPDATE_SALES_PERSON_FROM_ADMIN = `${SECURED_API_URL}/sales-person/update`;
export const UPDATE_SALES_PERSON = `${SECURED_API_URL}/sales-person/update`;
export const DELETE_SALES_PERSON = `${SECURED_API_URL}/sales-person/delete`;
export const GET_SALES_PERSON_PROFILE = `${SECURED_API_URL}/sales-person-profile`;

//subscription plan
export const GET_ALL_SUBSCRIPTION_PLANS = `${SECURED_API_URL}/subscription-plans`;
export const GET_SUBSCRIPTION_PLAN_BY_ID = `${SECURED_API_URL}/subscription-plans/id`;
export const POST_SUBSCRIPTION_PLAN = `${SECURED_API_URL}/subscription-plans/create`;
export const UPDATE_SUBSCRIPTION_PLAN = `${SECURED_API_URL}/subscription-plans/update`;
export const DELETE_SUBSCRIPTION_PLAN = `${SECURED_API_URL}/subscription-plans/delete`;

//global config
export const GET_ALL_GLOBAL_CONFIGS = `${SECURED_API_URL}/global-configs`;
export const GET_ALL_ACTIVE_GLOBAL_CONFIGS = `${SECURED_API_URL}/global-configs/active`;
export const POST_GLOBAL_CONFIG = `${SECURED_API_URL}/global-configs/create`;
export const UPDATE_GLOBAL_CONFIG = `${SECURED_API_URL}/global-configs/update`;
export const GET_GLOBAL_CONFIG_BY_ID = `${SECURED_API_URL}/global-configs/id`;
export const DELETE_GLOBAL_CONFIG = `${SECURED_API_URL}/global-configs/delete`;

// Change Password
export const CHANGE_LOGGED_USER_PASSWORD = `${SECURED_API_URL}/users/change-password`;
export const CHANGE_ANOTHER_USER_PASSWORD_BY_ID = `${SECURED_API_URL}/users/change-password`;

export const UPLOAD_PRODUCT_FILES = `${SECURED_API_URL}/products/update/by/files`;

// --------------------- pos Start ---------------------------

// --------------------- pos Customer ------------------------
export const POS_GET_ALL_CUSTOMER_BY_SHOP_ID = `${POS_SECURED_API_URL}/customers`;
export const POS_GET_CUSTOMER_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/customers`;
export const POS_GET_CUSTOMER_RECEIPT_BY_SHOP_ID_BY_LOCAL_ID = `${POS_SECURED_API_URL}/reports/customer-payment-receipt`;
export const POS_GET_CUSTOMER_BY_SHOP_ID_BY_LOCAL_ID = `${POS_SECURED_API_URL}/customers/by/local-id`;
export const POS_CREATE_CUSTOMER_BY_SHOP_ID = `${POS_SECURED_API_URL}/customers/create`;
export const POS_DELETE_CUSTOMER_BY_ID = `${POS_SECURED_API_URL}/customers/delete`;
export const POS_GET_ALL_LITE_CUSTOMER_BY_SHOP_ID = `${POS_SECURED_API_URL}/customers/lite`;
export const POS_CUSTOMER_QUEUE_SYNC = `${POS_SECURED_API_URL}/customers/queue-sync`;
export const POS_CUSTOMER_QUEUE_SYNC_ACKNOWLEDGEMENT = `${POS_SECURED_API_URL}/customers/queue-sync/acknowledgement`;
export const POS_CUSTOMER_SYNC_BY_SHOP_ID = `${POS_SECURED_API_URL}/customers/sync`;
export const POS_UPDATE_CUSTOMER_BY_ID_SHOP_ID = `${POS_SECURED_API_URL}/customers/update`;

// CUSTOMER IMAG UPLOAD
export const POS_CUSTOMER_IMAGE_UPLOAD_PATH = `${SECURED_API_URL}/customer-pic/upload`;

// --------------------- pos Expense ------------------------
export const POS_GET_ALL_EXPENSE_BY_SHOP_ID = `${POS_SECURED_API_URL}/expenses`;
export const POS_GET_EXPENSE_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/expenses`;
export const POS_CREATE_EXPENSE_BY_SHOP_ID = `${POS_SECURED_API_URL}/expenses/create`;
export const POS_EXPENSE_QUEUE_SYNC = `${POS_SECURED_API_URL}/expenses/queue-sync`;
export const POS_EXPENSE_QUEUE_SYNC_ACKNOWLEDGEMENT = `${POS_SECURED_API_URL}/expenses/queue-sync/acknowledgement`;
export const POS_EXPENSE_SYNC_BY_SHOP_ID = `${POS_SECURED_API_URL}/expenses/sync`;
export const POS_UPDATE_EXPENSE_BY_ID_SHOP_ID = `${POS_SECURED_API_URL}/expenses/update`;

// --------------------- pos Inventory ------------------------
export const POS_GET_ALL_INVENTORIES_BY_SHOP_ID = `${POS_SECURED_API_URL}/inventories`;
export const POS_GET_INVENTORY_BY_ID = `${POS_SECURED_API_URL}/inventories`;
export const POS_CREATE_INVENTORY_BY_SHOP_ID = `${POS_SECURED_API_URL}/inventories/create`;
export const POS_INVENTORY_SYNC_BY_SHOP_ID = `${POS_SECURED_API_URL}/inventories/sync`;
export const POS_UPDATE_INVENTORY_BY_ID_SHOP_ID = `${POS_SECURED_API_URL}/inventories/update`;
export const POS_GET_ALL_INVENTORY_VENDOR_BY_ID_SHOP_ID = `${POS_SECURED_API_URL}/inventories/vendor`;
export const POS_GET_INVENTORY_REPORT_BY_SHOP_ID = `${POS_SECURED_API_URL}/inventory-entries/inventory-missing-report`;
export const POS_GET_SALES_REPORT_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/sale/report`;


// --------------------- pos Payment ------------------------
export const POS_GET_ALL_CUSTOMER_PAYMENT_BY_SHOP_ID = `${POS_SECURED_API_URL}/payments/customer`;
export const POS_CANCEL_CUSTOMER_PAYMENT_BY_ID_BY_SHOP_ID = `${POS_SECURED_API_URL}/payments/customers/cancel`;
export const POS_CREATE_CUSTOMER_PAYMENT_BY_SHOP_ID = `${POS_SECURED_API_URL}/payments/customers/create`;
export const POS_CUSTOMER_PAYMENT_QUEUE_SYNC = `${POS_SECURED_API_URL}/payments/customers/queue-sync`;
export const POS_CUSTOMER_PAYMENT_QUEUE_SYNC_ACKNOWLEDGEMENT = `${POS_SECURED_API_URL}/payments/customers/queue-sync/acknowledgement`;
export const POS_CUSTOMER_PAYMENT_SYNC_BY_SHOP_ID = `${POS_SECURED_API_URL}/payments/customers/sync`;

export const POS_GET_ALL_VENDOR_PAYMENT_BY_SHOP_ID = `${POS_SECURED_API_URL}/payments/vendor`;
export const POS_CANCEL_VENDOR_PAYMENT_BY_ID_BY_SHOP_ID = `${POS_SECURED_API_URL}/payments/vendors/cancel`;
export const POS_CREATE_VENDOR_PAYMENT_BY_SHOP_ID = `${POS_SECURED_API_URL}/payments/vendors/create`;
export const POS_VENDOR_PAYMENT_QUEUE_SYNC = `${POS_SECURED_API_URL}/payments/vendors/queue-sync`;
export const POS_VENDOR_PAYMENT_QUEUE_SYNC_ACKNOWLEDGEMENT = `${POS_SECURED_API_URL}/payments/vendors/queue-sync/acknowledgement`;
export const POS_VENDOR_PAYMENT_SYNC_BY_SHOP_ID = `${POS_SECURED_API_URL}/payments/vendors/sync`;

// --------------------- pos Product ------------------------
export const POS_GET_ALL_PRODUCT_BY_SHOP_ID = `${POS_SECURED_API_URL}/products`;
export const POS_GET_ALL_PRODUCT_FOR_SALE_BY_SHOP_ID = `${POS_SECURED_API_URL}/products/for-sale-&-purchase/products`;
export const POS_GET_ALL_PUBLIC_PRODUCT = `${ROOT_URL}/public/products`;
export const POS_GET_PRODUCT_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/products`;
export const POS_PRODUCT_SYNC_BY_SHOP_ID = `${POS_SECURED_API_URL}/products/sync`;
export const OS_SEARCH_PRODUCTS_URL = `${PUBLIC_API_URL}/products`;
export const POS_GET_ALL_REQUIRED_PRODUCTS = `${POS_SECURED_API_URL}/products/requistion`;
export const POS_CREATE_PRODUCT = `${POS_SECURED_API_URL}/products/create`;
export const POS_UPDATE_PRODUCT = `${POS_SECURED_API_URL}/products/update`;
export const DELETE_POS_PRODUCT = `${POS_SECURED_API_URL}/products/delete`;
export const GENERATE_PRODUCT_EXCEL = `${POS_SECURED_API_URL}/products/export-excel`;
export const POS_GET_PRODUCT_BY_BARCODE = `${POS_SECURED_API_URL}/products/by-barCode`;

export const POS_GET_ALL_PRODUCT_INVENTORIES = `${POS_SECURED_API_URL}/products/product-inventories`;


// Product Barcode
export const GENERATE_PRODUCT_BAR_CODE_PATH = `${POS_SECURED_API_URL}/products/generate/bar-code`;

export const GET_ALL_POS_COMPANIES = `${POS_SECURED_API_URL}/companies`;

export const SALE_RECEIPT_URL = `${POS_ROOT_URL}/public/sale-receipt`;


// --------------------- pos requisition-new-old ------------------------
export const POS_CREATE_REQUISITION = `${POS_SECURED_API_URL}/requisition/create`;
export const POS_GET_ALL_REQUISITION_BY_SHOP_ID = `${POS_SECURED_API_URL}/requisition`;
export const POS_GET_REQUISITION_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/requisition/id`;
export const POS_UPDATE_REQUISITION_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/requisition/update`;
export const POS_CANCEL_REQUISITION_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/requisition/cancel`;

// --------------------- pos All Public Product By Company ------------------------
export const POS_ALL_PRODUCT_BY_COMPANY_ID = `${ROOT_URL}/public/products/by`;

// --------------------- pos Report ------------------------
export const POS_GET_REPORT_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports`;
export const POS_GENERATE_EXCEL_REPORT_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/daily-report/excel`;
export const POS_GET_ACCOUNT_BALANCE_REPORT = `${POS_SECURED_API_URL}/reports/account-balance`;

// --------------------- pos Sale ------------------------
export const POS_GET_ALL_SALE_BY_SHOP_ID = `${POS_SECURED_API_URL}/sales`;
export const POS_SALES_CASH_RECEIVED_BY_ID_BY_SHOP_ID = `${POS_SECURED_API_URL}/sales/cash-received`;
export const POS_SALES_NOT_CASH_RECEIVED_BY_ID_BY_SHOP_ID = `${POS_SECURED_API_URL}/sales/not-cash-received`;
export const POS_CASH_RECEIVED_REFRESH = `${POS_SECURED_API_URL}/sales/cash-received-refresh`

export const POS_CREATE_SALES_BY_SHOP_ID = `${POS_SECURED_API_URL}/sales/create`;
export const POS_SALES_QUEUE_SYNC = `${POS_SECURED_API_URL}/sales/queue-sync`;
export const POS_SALES_QUEUE_SYNC_ACKNOWLEDGEMENT = `${POS_SECURED_API_URL}/sales/queue-sync-acknowledgement`;
export const POS_SALES_SYNC_BY_SHOP_ID = `${POS_SECURED_API_URL}/sales/sync`;
export const POS_UPDATE_SALES_BY_ID_BY_SHOP_ID = `${POS_SECURED_API_URL}/sales/update`;
export const POS_GET_SALE_BY_ID = `${POS_SECURED_API_URL}/sales`;
export const POS_GET_SALE_INVOICE = `${POS_SECURED_API_URL}/reports/sale/invoice`;
export const POS_GET_SALE_INVOICE_DATA = `${POS_SECURED_API_URL}/reports/sale/invoice-data`;

// --------------------- pos Vendor ------------------------
export const POS_GET_VENDOR_REPORTS_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/vendor-payment-receipt`;
export const POS_GET_ALL_VENDOR_BY_SHOP_ID = `${POS_SECURED_API_URL}/vendors`;
export const POS_GET_VENDOR_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/vendors`;
export const POS_GET_VENDOR_BY_SHOP_ID_BY_LOCAL_ID = `${POS_SECURED_API_URL}/vendors/by/local-id`;
export const POS_CREATE_VENDOR_BY_SHOP_ID = `${POS_SECURED_API_URL}/vendors/create`;
export const POS_DELETE_VENDOR_BY_ID = `${POS_SECURED_API_URL}/vendors/delete`;
export const POS_GET_ALL_LITE_VENDOR_BY_SHOP_ID = `${POS_SECURED_API_URL}/vendors/lite`;
export const POS_VENDOR_QUEUE_SYNC = `${POS_SECURED_API_URL}/vendors/queue-sync`;
export const POS_VENDOR_QUEUE_SYNC_ACKNOWLEDGEMENT = `${POS_SECURED_API_URL}/vendors/queue-sync/acknowledgement`;
export const POS_VENDOR_SYNC_BY_SHOP_ID = `${POS_SECURED_API_URL}/vendors/sync`;
export const POS_UPDATE_VENDOR_BY_ID_SHOP_ID = `${POS_SECURED_API_URL}/vendors/update`;

//pos_sales_person // representative
export const POS_GET_ALL_SALES_PERSON = `${SECURED_API_URL}/shop-owners/sales-person/all`;
export const POS_GET_SALES_PERSON_BY_ID = `${SECURED_API_URL}/shop-owners/sales-person/id`;
export const POS_CREATE_SALES_PERSON = `${SECURED_API_URL}/shop-owners/sales-person/create`;
export const POS_UPDATE_SALES_PERSON = `${SECURED_API_URL}/shop-owners/sales-person/update`;
export const POS_DELETE_SALES_PERSON = `${SECURED_API_URL}/shop-owners/sales-person/delete`;

//roles
export const POS_GET_ALL_ROLES = `${SECURED_API_URL}/shop-owners/roles`;

// --------------------- pos Inventory Expired ------------------------
export const POS_GET_EXPIRED_ITEMS = `${POS_SECURED_API_URL}/inventory-entries/expired/by`;

// --------------------- pos POSShop Transaction Accounts ------------
export const POS_GET_SHOP_TRANSACTION_ACCOUNTS = `${POS_SECURED_API_URL}/shop-users`;
export const POS_CREATE_SHOP_TRANSACTION_ACCOUNT = `${POS_SECURED_API_URL}/shop-users/create`;
export const POS_UPDATE_SHOP_TRANSACTION_ACCOUNT = `${POS_SECURED_API_URL}/shop-users/update`;

// --------------------- pos Transaction ------------
export const POS_GET_SHOP_TRANSACTIONS = `${POS_SECURED_API_URL}/shop-user-transactions`;
export const POS_GET_SHOP_TRANSACTION_BY_ID = `${POS_SECURED_API_URL}/shop-user-transactions`;
export const POS_CREATE_SHOP_TRANSACTION = `${POS_SECURED_API_URL}/shop-user-transactions/create`;
export const POS_CANCEL_SHOP_TRANSACTION = `${POS_SECURED_API_URL}/shop-user-transactions/cancel`;

// =================== reconciliation ==================
export const POS_GET_ALL_RECONCILIATION = `${POS_SECURED_API_URL}/reconciliations`;
export const POS_CREATE_RECONCILIATION = `${POS_SECURED_API_URL}/reconciliations/create`;
export const POS_CANCEL_RECONCILIATION = `${POS_SECURED_API_URL}/reconciliations/cancel`;

// =================== pos report==================
export const POS_GENERATE_REQUISITION_PDF = `${POS_SECURED_API_URL}/reports/requisition/pdf`;
export const GET_PMS_DASHBOARD_TOP_DATA = `${POS_SECURED_API_URL}/reports/fixed-count`;

// =================== offline config ==================
export const GET_POS_OFFLINE_CONFIG_LIST = `${POS_SECURED_API_URL}/offline-configs`;
export const GET_POS_OFFLINE_CONFIG_BY_ID = `${POS_SECURED_API_URL}/offline-configs/id`;
export const GENERATE_POS_OFFLINE_CONFIG_TOKEN_BY_SHOP_ID = `${POS_SECURED_API_URL}/offline-configs/generate-token`;
export const CREATE_POS_OFFLINE_CONFIG = `${POS_SECURED_API_URL}/offline-configs/create`;
export const UPDATE_POS_OFFLINE_CONFIG = `${POS_SECURED_API_URL}/offline-configs/update`;

// pos config
export const GET_PRINTING_CONFIG_BY_ID = `${POS_SECURED_API_URL}/shop-configs/shop-id`;
export const UPDATE_PRINTING_CONFIG_BY_ID = `${POS_SECURED_API_URL}/shop-configs/create-update`;
export const POS_CUSTOMER_SHOP_PATH = `${SECURED_API_URL}/shop-pic/upload`;


// Shop setting
export const GET_SHOP_SETTING_BY_ID = `${POS_SECURED_API_URL}/shop-settings/shop-id`;
export const UPDATE_SHOP_SETTING_BY_ID = `${POS_SECURED_API_URL}/shop-settings/create-update`;

// Online Order Config
export const UPDATE_ORDER_SETTING_BY_ID = `${POS_SECURED_API_URL}/order-configs/create-update`;
export const GET_ORDER_CONFIG_BY_ID = `${POS_SECURED_API_URL}/order-configs/shop-id`;

// online store config
export const GET_POS_ONLINE_STORE_CONFIG = `${SECURED_API_URL}/shops/profile`;
export const UPDATE_POS_ONLINE_STORE_CONFIG = `${SECURED_API_URL}/shop-owners/shops/update`;

// POS Category
export const GET_ALL_CATEGORY_BY_SHOP_ID = `${POS_SECURED_API_URL}/product-types`;
export const CREATE_CATEGORY_BY_SHOP_ID = `${POS_SECURED_API_URL}/product-types/create`;
export const POS_GET_CATEGORY_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/product-types`;
export const POS_UPDATE_CATEGORY_BY_ID_SHOP_ID = `${POS_SECURED_API_URL}/product-types/update`;
export const POS_DELETE_CATEGORY_BY_ID = `${POS_SECURED_API_URL}/product-types/delete`;

// POS companies
export const GET_ALL_SHOP_COMPANY_BY_SHOP_ID = `${POS_SECURED_API_URL}/shop-companies`;
export const CREATE_SHOP_COMPANY_BY_SHOP_ID = `${POS_SECURED_API_URL}/shop-companies/create`;
export const POS_GET_SHOP_COMPANY_BY_SHOP_ID_BY_ID = `${POS_SECURED_API_URL}/shop-companies`;
export const POS_UPDATE_SHOP_COMPANY_BY_ID_SHOP_ID = `${POS_SECURED_API_URL}/shop-companies/update`;
export const POS_DELETE_SHOP_COMPANY_BY_ID = `${POS_SECURED_API_URL}/shop-companies/delete`;

// Reports
export const GET_ALL_STOCK_DETAILS_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/stock-details`;
export const GET_ALL_JOURNAL_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/journal-details`;
export const GET_ALL_CUSTOMERS_PAYMENT_STATUS_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/customer`;
export const GET_ALL_VENDORS_PAYMENT_STATUS_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/vendor`;
export const GET_ALL_SALE_HISTORY_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/sale/details`;
export const GET_ALL_PURCHASE_HISTORY_BY_SHOP_ID = `${POS_SECURED_API_URL}/reports/purchase/details`;

// Subscription
export const GET_ALL_INSTALLATION_CHARGE = `${SECURED_API_URL}/shops/transactions/unpaid`;
export const GET_ALL_PAYMENT_HISTORY = `${SECURED_API_URL}/shops/transactions/by-shop`;
export const GET_ALL_MONTHLY_SUBSCRIPTION = `${SECURED_API_URL}/shops/subscription/plans`;

// order
export const GET_ORDERS_BY_SHOP_BY_ID = `${POS_SECURED_API_URL}/orders`;
export const GET_ORDERS_BY_ID = `${POS_SECURED_API_URL}/orders`;
export const UPDATE_ORDER_STATUS = `${POS_SECURED_API_URL}/orders/state/update`;

// --------------------- pos End ---------------------------

export const BKASH_PAYMENT = `${SECURED_API_URL}/bkash/payment`;

// Transaction
export const ONLINE_PAYMENT_HISTORY = `${SECURED_API_URL}/shops/transactions/all`;
export const REFUND_PAYMENT = `${SECURED_API_URL}/bkash/refund`;

export const discountPercentage = "PERCENTAGE";


export const TINY_MCE_API_KEY = "8zg45q4flc4tswsjif4w1uvttun169j7xgocjg00wviuuplf";

//color code
export const RED = "#dc3545";
export const GREEN = "#28a745";
export const YELLOW = "#ffc107";
export const PRIMARY = "#40A9FF"

export const EIGHTY_MM_PAPER = "EIGHTY_MM";
export const FIFTY_EIGHT_MM_PAPER = "FIFTY_EIGHT_MM";
export const LANGUAGE = "LANGUAGE"

export const CURRENCY_KEY = "currency";
export const CURRENCY_DIGIT_KEY = "currency-digit";

export const defaultCountry = {
    "code": "BD",
    "label": "Bangladesh",
    "phone": "+880",
    "phoneLength": 10,
    "emoji": "🇧🇩"
}

const ORDER_PLACED = "Order placed";
const CANCEL = "Cancel";
const AUTHORIZED = "Authorized";
const PICK_UP_IN_PROGRESS = "Pickup In Progress";
const IN_DELIVERY = "In Delivery";
const DELIVERED = "Delivered";

export const orderStatusList = [
    {title: ORDER_PLACED, color: "#FF0000"},
    {title: CANCEL, color: "#000000"},
    {title: AUTHORIZED, color: "#00B628"},
    {title: PICK_UP_IN_PROGRESS, color: "#BB00A8"},
    {title: IN_DELIVERY, color: "#00509B"},
    {title: DELIVERED, color: "#00AD1C"},
]

export const orderStatusMap = (value) => {
    const paymentStatusColor = {
        "ORDER_PLACED": "Order placed",
        "CANCEL": "Cancel",
        "AUTHORIZED": "Authorized",
        "PICK_UP_IN_PROGRESS": "Pickup In Progress",
        "IN_DELIVERY": "In Delivery",
        "DELIVERED": "Delivered",
    }
    return paymentStatusColor[value];
}

export const selectBgcClass = {
    "Order placed": "ORDER_PLACED",
    "Cancel": "CANCEL",
    "Authorized": "AUTHORIZED",
    "Pickup In Progress": "PICK_UP_IN_PROGRESS",
    "In Delivery": "IN_DELIVERY",
    "Delivered": "DELIVERED",
}

export const orderStatusMapColor = (value) => {
    const paymentStatusColor = {
        "ORDER_PLACED": "#FF0000",
        "CANCEL": "#000000",
        "AUTHORIZED": "#00B628",
        "PICK_UP_IN_PROGRESS": "#BB00A8",
        "IN_DELIVERY": "#00509B",
        "DELIVERED": "#00AD1C",
    }
    return paymentStatusColor[value];
}

