import axios from "axios";
import * as URL from "../helpers/Constants";
import {getShopId} from "../helpers/Utils";

export default class POSInventoryServices {

    static getInventories(params) {
        return axios.get(
            `${URL.POS_GET_ALL_INVENTORIES_BY_SHOP_ID}/${getShopId()}`,
            {
                ...POSInventoryServices.getAuthHeader(),
                params,
            }
        );
    }

    static getInventoryById(id) {
        return axios.get(
            `${URL.POS_GET_INVENTORY_BY_ID}/${getShopId()}/${id}`,
            POSInventoryServices.getAuthHeader()
        );
    }

    static createInventory(shopId, data) {
        return axios.post(`${URL.POS_CREATE_INVENTORY_BY_SHOP_ID}/${shopId}`, data, POSInventoryServices.getAuthHeader());
    }

    // static createSale(shopId, data) {
    //     return axios.post(`${URL.POS_CREATE_SALES_BY_SHOP_ID}/${shopId}`, data, POSInventoryServices.getAuthHeader());
    // }

    static editInventory(id, shopId, data, params) {
        return axios.put(`${URL.POS_UPDATE_INVENTORY_BY_ID_SHOP_ID}/${id}/${shopId}`, data, {
            ...POSInventoryServices.getAuthHeader(), params
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        return {headers: {"Authorization": `Bearer ${accessToken}`}};
    }

    static getInventoryReport(shopId, params) {
        return axios.get(
            `${URL.POS_GET_INVENTORY_REPORT_BY_SHOP_ID}/${shopId}`,
            {
                ...POSInventoryServices.getAuthHeader(),
                params,
            }
        );
    }

    static getSalesReport(shopId, params) {
        return axios.get(
            `${URL.POS_GET_SALES_REPORT_BY_SHOP_ID}/${shopId}`,
            {
                ...POSInventoryServices.getAuthHeader(),
                params,
            }
        );
    }

}
