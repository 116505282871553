import React, {createContext, useState} from 'react';
import RoleService from "../services/RoleService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";

export const RoleContext = createContext("RoleContext");

const RoleContextProvider = ({children}) => {

    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [roles, setRoles] = useState([]);
    const [role, setRole] = useState(null);
    const [totalElements, setTotalElements] = useState(0);

    async function getAllRoles({page, size, id, alias}) {

        try {
            setLoading(true);
            setErrorMsg('');
            const res = await RoleService.getAllRoles(page, size, id, alias);
            setRoles(res.data.content)
            setTotalElements(res.data.totalElements);
            setLoading(false);
            return res.data.content;
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }

    }

    async function addRole(data) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await RoleService.addRole(data)
            setRole(res.data);
            setLoading(false);
            Toast("success", "CREATED", `Role Id - ${res.data.id} created successfully!`);
            return true;
        } catch (err) {
            const message = getErrorMessage(err);
            setErrorMsg("Can not add Roles. Please check the console.");
            Toast("error", "Error", `Can not save Role ${message}`);
            return false;
        }

    }

    async function updateRole(data, id) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await RoleService.updateRole(data, id);
            setRole(res.data);
            setLoading(false);
            Toast("success", "Success", `Role Id - ${res.data.id} updated successfully!`);
            return true;
        } catch (error) {
            const message = getErrorMessage(error);
            setErrorMsg("Can not update Role. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }

    async function deleteRole(id) {

        if (!window.confirm("Are you sure you want to delete this Role ?")) return;

        try {
            setLoading(true);
            setErrorMsg('');
            await RoleService.deleteRole(id);
            let updatedRoles = roles;
            updatedRoles = roles.filter(e => e.id !== id);
            setRoles(updatedRoles);
            setLoading(false);
            return true;
        } catch (error) {
            setErrorMsg("Can not delete Roles. Please check the console.");
            return false;
        }
    }

    async function changeUserRole(roleId, userId) {
        try {
            await RoleService.changeRole(roleId, userId);
            Toast("success", "Success", "Role Changed Successfully !");
            return true;
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }

    }

    const getRoleById = async (id) => {
        try {
            setLoading(true);
            const res = await RoleService.getRoleById(id);
            setRole(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    /*==== Shop Role ======*/
    async function addShopOwnerRole(data, shopId) {

        try {
            setErrorMsg('');
            setLoading(true);
            const res = await RoleService.addShopOwnerRole(data, shopId)
            setRole(res.data);
            setLoading(false);
            Toast("success", "CREATED", `Role Id - ${res.data.id} created successfully!`);
            return true;
        } catch (err) {
            const message = getErrorMessage(err);
            setErrorMsg("Can not add Roles. Please check the console.");
            Toast("error", "Error", `Can not save Role ${message}`);
            return false;
        }

    }

    async function updateShopOwnerRole(data, id, shopId) {
        try {
            setLoading(true);
            setErrorMsg('');
            const res = await RoleService.updateShopOwnerRole(data, id, shopId);
            setRole(res.data);
            setLoading(false);
            Toast("success", "Success", `Role Id - ${res.data.id} updated successfully!`);
            return true;
        } catch (error) {
            const message = getErrorMessage(error);
            setErrorMsg("Can not update Role. Please check the console.");
            Toast("error", "Error", message);
            return false;
        }

    }

    const getPOSRoleById = async (id) => {
        try {
            setLoading(true);
            const res = await RoleService.getPOSRoleById(id);
            setRole(res.data);
            setLoading(false);
            return res.data;
        } catch (error) {
            setLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }


    return (
        <RoleContext.Provider
            value={{
                loading,
                errorMsg,
                role,
                roles,
                totalElements,
                getAllRoles,
                updateRole,
                updateShopOwnerRole,
                deleteRole,
                getRoleById,
                changeUserRole,
                addRole,
                getPOSRoleById,
                addShopOwnerRole
            }}
        >
            {children}
        </RoleContext.Provider>
    );
}

export default RoleContextProvider;
