import * as URL from "../helpers/Constants";
import axios from "axios";

export default class RoleService {

    static getAllRoles(page, size, id, alias) {
        return axios.get(URL.GET_ALL_ROLES, {...RoleService.getAuthHeader(), params: {page, size, id, alias}});
    }

    static addRole(data) {
        return axios.post(`${URL.ADD_ROLE}`, data, {...RoleService.getAuthHeader()});
    }

    static updateRole(data, id) {
        return axios.put(`${URL.UPDATE_ROLE}/${id}`, data, RoleService.getAuthHeader());
    }

    static changeRole(roleId, userId) {
        return axios.patch(`${URL.CHANGE_ROLE}/${userId}`, null, {params: {roleId}, ...RoleService.getAuthHeader()});
    }

    static deleteRole(id) {
        return axios.delete(`${URL.DELETE_ROLE}/${id}`, RoleService.getAuthHeader());
    }

    static getRoleById(id) {
        return axios.get(`${URL.GET_ROLE_BY_ID}/${id}`, RoleService.getAuthHeader());
    }

    /*====Shop=====*/
    static getPOSRoleById(id) {
        return axios.get(`${URL.GET_SHOP_ROLE_BY_ID}/${id}`, RoleService.getAuthHeader());
    }

    static addShopOwnerRole(data, shopId) {
        return axios.post(`${URL.ADD_SHOP_OWNER_ROLE}/${shopId}`, data, {
                ...RoleService.getAuthHeader()
            }
        );
    }

    static updateShopOwnerRole(data, id, shopId) {
        return axios.put(`${URL.EDIT_SHOP_OWNER_ROLE}/${id}/${shopId}`, data, RoleService.getAuthHeader());
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }
}
