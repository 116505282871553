import * as URL from "../../helpers/Constants";
import axios from "axios";

export default class ShopService {


    static getAllShops(page, size, id, name, shopOwnerName, shopOwnerPhone) {
        return axios.get(URL.GET_ALL_SHOPS, {
            ...ShopService.getAuthHeader(),
            params: {page, size, id, name, shopOwnerName, shopOwnerPhone}
        });
    }

    static getShopById(id) {
        return axios.get(`${URL.GET_SHOP_BY_ID}/${id}`, ShopService.getAuthHeader());
    }

    static getSubscriptionPlanForShop() {
        return axios.get(`${URL.GET_SUBSCRIPTION_PLAN_FOR_SHOP}`, ShopService.getAuthHeader());
    }

    static addShop(data, shopOwnerId) {

        return axios.post(URL.POST_SHOP, data, {
            ...ShopService.getAuthHeader(),
            params: {shopOwnerId}
        });
    }

    static updateShop(data, id) {
        return axios.put(`${URL.UPDATE_SHOP}/${id}`, data, ShopService.getAuthHeader());
    }

    static deleteShop(id) {
        return axios.delete(`${URL.DELETE_SHOP}/${id}`, ShopService.getAuthHeader());
    }

    static getShopRolesById(id) {
        return axios.get(`${URL.GET_SHOP_ROLES_BY_ID}/${id}`, ShopService.getAuthHeader());
    }

    static getPosShopRolesById(id) {
        return axios.get(`${URL.GET_POS_SHOP_ROLES_BY_ID}/${id}`, ShopService.getAuthHeader());
    }

    static getShopStatistics(id) {
        return axios.get(`${URL.GET_SHOP_STATISTICS_URL}/${id}`, ShopService.getAuthHeader());
    }

    static checkShopAsOnline(id, status) {
        // console.log(id,status_payment)
        return axios.patch(`${URL.CHECK_SHOP_AS_ONLINE}/${id}`, null, {
            ...ShopService.getAuthHeader(),
            params: {status}
        });
    }

    static getAuthHeader() {
        const accessToken = localStorage.getItem("ems_access_token");
        const options = {headers: {"Authorization": `Bearer ${accessToken}`}}
        return options;
    }

    // static changeShopStatus(email, status_payment) {
    //     return axios.post(`${URL.UPDATE_SHOP_STATUS}/${email}?status_payment=${status_payment}`)
    // }
}